body.homepage {

  /* hide close button on video play on homagepage
  as users can click in the background to close it */
  .close-message {
    display: none;
  }

  #torso {
    -webkit-overflow-scrolling: touch;
    margin-top: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fade-in-hr-setup(@background) {
    &:after {
      position: relative;
      width: 134px;
      height: 2px;
      background: @background;
      content: '';
      opacity: 1;
      display: block;
      margin: 0 auto;
      top: 30px;
    }
  }

  .base {
    width: 100vw;
  }

  .phil-bg {
    background: #131313 url(~img/partials/homepage/intro_phil.jpg) no-repeat 80% top;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 746px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  button {
    border: none;
    outline: none;
  }

  hr {
    background: #cdcfd3;
    border: none;
    display: inline-block;
    height: 1px;
    width: 134px;
  }

  p {
    color: #5e6367;
    font-size: 17px;
    line-height: 1.7;
    padding: 0;
  }



  section {
    .container {
      margin: 0 auto;
      max-width: 1660px;
    }
  }

  section.intro {
    overflow: hidden;

    .container {
      position: relative;

      // for the intro arrows
      height: 100%;

      display: flex;
      align-items: center;
      padding: 8%;

      .info {
        width: 478px;
        z-index: 1020;
      }
    }

    h1 {
      color: #fff;
      font-size: 96px;
      font-weight: 700;
      line-height: 1.1;
    }

    p {
      color: #949494;
      font-size: 18px;
      font-family: @fontHeading;
      margin: 12px 0 30px;
      max-width: 60%;
    }

    a {
      .border-radius(4px);
      .transition(all, 0.2s, ease-out);
      cursor: pointer;
      display: inline-block;
      background: #cf0f1b;
      color: #fff;
      font-family: @fontHeading;
      font-size: 14px;
      padding: 18px 80px 18px 20px;
      text-decoration: none;
      border-radius: 30px;
      position: relative;

      &.plans-pricing {
        box-shadow: 3px 4px 39px 0px rgba(207,15,27,1);
      }

      span {
        display: none;
      }

      &:hover {
        transform: scale(1.1);
      }

      .arrow-bg {
        background-image: url("~img/partials/homepage/sign-up-arrow.png");
        width: 22px;
        height: 11px;
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 23px;
      }
    }

    .flag-wrapper {
      position: relative;
    }
    .flags {
      position: absolute;
      margin: 30px 0;
      width: 125px;
      border: 1px solid #acb2bc;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      list-style: none;
      overflow: hidden;
      padding: 0;
      background: white;

      &:hover {
        border-radius: 15px;

        li {
          display: flex !important;
          order: 2;

          &:hover {
            background-color: #f3f3f3;
          }
        }

        .dropdown_arrow {
          visibility: hidden;
        }
      }

      li {
        justify-content: center;
        display: none;
      }

      a {
        color: #000;
        font-family: @fontOpenSans;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 125px;
        text-decoration: none;
        padding: 6px 10px;
        background: white;
        &:hover {
          background-color: #acb2bc;
        }
      }

      .dropdown_arrow {
        height: 6px;
        visibility: visible;
        display: inline-block;
      }

      .flag {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        background-position: center;
      }

      li {
        order: 2;
        &.english {
          .flag {
            background-image: url("~img/flags/usa.png");
          }
        }
        &.russia {
          .flag {
            background-image: url("~img/flags/russia.png")
          }
        }
        &.brazil {
          .flag {
            background-image: url("~img/flags/brazil.png");
          }
        }
      }

      &.en-us {
        li.english {
          display: flex;
          order: 1;
        }
      }

      &.ru {
        li.russia {
          display: flex;
          order: 1;
        }
      }

      &.pt {
        li.brazil {
          display: flex;
          order: 1;
        }
      }
    }

    .signature {
      display: inline-block;
      width: 134px;
      top: 14px;
      position: relative;
      left: 28px;
    }

    .take-a-tour {
      cursor: pointer;
      position: absolute;
      width: 100%;
      bottom: 12px;
      left: 0;
      display: none;
      text-align: center;
      z-index: 1020;

      .icon {
        display: inline-block;
        height: 26px;
        margin-top: 30px;
        width: 26px;

        path {
          opacity: 0.2;
        }

        @keyframes pulsate-arrow-one {
          0% { opacity: 1}
          33% { opacity: 0.6 }
          66% { opacity: 0.2 }
          100% { opacity: 0.2 }
        }

        @-webkit-keyframes pulsate-arrow-one {
          0% { opacity: 1}
          33% { opacity: 0.6 }
          66% { opacity: 0.2 }
          100% { opacity: 0.2 }
        }

        .one {
          .animation(pulsate-arrow-one, 2s, 0, ease-out, infinite);
        }

        .two {
          .animation(pulsate-arrow-one, 2s, 0.3s, ease-out, infinite);
        }

        .three {
          .animation(pulsate-arrow-one, 2s, 0.55s, ease-out, infinite);
        }
      }

      h2 {
        color: #fff;
        font-size: 13px;
        margin: 8px 0;
      }
    }
  }

  section.vision {
    background: #070910 url(~img/partials/homepage/homepage_vision_section_bg-min.png) no-repeat top;
    background-size: auto 100%;
    max-height: 742px;

    .container {
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        background: url(~img/visions/plo/vision-logo@2x-min.png) no-repeat center;
        height: 15vw;
        width: 90vw;
        max-height: 100px;
        max-width: 370px;
        background-size: auto 100%;
        color: transparent;
        margin-bottom: 20px;
        display: inline-block;
      }

      h4 {
        font-family: @fontOpenSans;
        color: #fff;
        font-size: 21px;
        font-weight: bold;
      }

      p {
        font-family: @fontOpenSans;
        font-size: 16px;
        color: #fff;
        padding: 50px 0;
      }

      ul {
        list-style: none;

        li {
          font-family: @fontOpenSans;
          font-size: 16px;
          color: #fff;
          margin-bottom: 15px;

          &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            position: relative;
            top: 5px;
            margin-right: 10px;
            background-image: url(~img/misc/checkmark_circled_black.png);
            background-size: 100% 100%;
            filter: invert(43%) sepia(94%) saturate(3573%) hue-rotate(330deg) brightness(95%) contrast(115%);
          }
        }
      }

      .left {
        width: 40%;
        padding: 150px 50px 190px 110px;
      }

      .right {
        width: 60%;

        .visions-video {
          background: transparent url(~img/partials/homepage/vision-video-homepage.webp) no-repeat left top;
          background-size: auto 100%;
          display: block;
          min-height: 660px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    @media (max-width: @widthDesktopMin) {
      max-height: none;
      background: #070910;

      .container {
        padding: 5%;
      }

      .inner {
        flex-direction: column;

        .left {
          width: 100%;
          padding: 0;
          text-align: center;
        }

        .right {
          width: auto;
          padding: 0;

          .visions-video {
            min-width: 90vw;
            min-height: calc(.78 * 90vw); // keep the 795 x 624 ratio
          }
        }
      }
    }
  }

  section.change-your-game {
    background: #f6f8f9;
    text-align: center;

    &.dancing-bg {
      .container {
        background-image: url(~img/partials/homepage/portugese-dancing-bg.jpg);
        background-size: cover;
        background-position-y: 18%;
      }
    }

    .container {
      .clearfix();
      background-color: #fff;
      padding: 84px 0 36px;
      position: relative;
      &.portugese {
        img {
          padding-bottom: 85px;
          width: 45%;
          max-width: 645px;
        }
      }
    }

    .header-icon {
      display: inline-block;
      margin-bottom: 14px;
      width: 24px;
    }

    .container .inner > h2 {
      .fade-in-hr-setup(#cdcfd3);
      margin-bottom: 110px;
    }


    h2 {
      color: #37393d;
      font-weight: 500;
      font-size: 38px;
      position: relative;
    }

    .sample-video {
      clear: both;
      float: right;
      position: relative;
      right: 2%;
      width: 64%;

      .example-video {
        background: transparent url(~img/partials/homepage/video-fan-new.jpg) no-repeat left top;
        background-size: auto 100%;
        display: block;
        min-height: 355px;
        position: relative;
        width: 100%;
      }

      .toggle-intro-video {
        cursor: pointer;
        width: 72%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }

      .video-trigger {
        .hide_text();
        background: transparent url(~img/partials/homepage/sample-video.png) no-repeat left top;
        background-size: auto 100%;
        bottom: -12px;
        cursor: pointer;
        height: 168px;
        left: 10px;
        position: absolute;
        width: 116px;
      }
    }

    .info {
      float: left;
      text-align: left;
      width: 24%;
      margin-left: 8%;
    }


    h3 {
      color: #37393d;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 14px;
    }

    &.portugese {
      .info {

      }
    }
  }


  .rio-modal {
    height: 100%;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 8000;

    .bg {
      transition: none;
      background: rgba(30, 31, 33, 0.9);
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 8010;
    }

    &.active {
      visibility: visible;

      .bg {
        .transition(all, 0.25s, ease-out);
        opacity: 1;
      }
    }

    .content {
      //border: 10px solid #17191a;
      //background: #17191a;
      display: inline-block;
      .transform(translateY(-50%));
      margin: 0 auto;
      position: relative;
      top: 50%;
      visibility: hidden;
      z-index: 8020;
      width: 720px;
      height: 390px;


      &.active {
        visibility: visible;
      }
    }

    iframe {
      display: block;
    }

    .close {
      .transition(opacity, 0.22s, ease-out);
      bottom: -28px;
      cursor: pointer;
      font-size: 11px;
      opacity: 0.6;
      position: absolute;
      right: 0;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }
    }
  }



  .toughest-pros {
    background: #fff url(~img/partials/homepage/video-divider.png) no-repeat center top;
    background-size: 66% auto;
    clear: both;
    padding: 74px 0 90px;
    width: 100%;
    display: block;
    margin-top: 100px;
    float: left;

    &.russian {
      margin-top: -100px; // do not leave blank gaps on the russian landing
    }
    &.portugese {
      .count {
         background-size: cover;
         background-image: url(~img/partials/homepage/portugese-number-bg.jpg);
         background-position: center;
         text-align: center;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         display: block;
         font-size: 98px;
         font-weight: 700;
         line-height: 1;
      }
      img {
        width: 40%;
        height: auto;
        margin: 30px auto;
        margin-bottom: 0;
      }
    }

    h2 {
      .fade-in-hr-setup(#cdcfd3);

      .count {
        color: #95865e;
        display: block;
        font-size: 98px;
        line-height: 1;
        font-family: @fontNowayLight;
      }

      margin-bottom: 60px;
    }

    p {
      margin: 0 auto 60px;
      width: 42%;
      font-family: @fontOpenSans;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }

    a.see-roster {
      display: none;
    }


    .slideshow {
      position: relative;
      opacity: 0;
      width: 100%;

      > div {
        display: inline-block;
        max-width: 1092px;
        margin: 0 auto;
        position: relative;
        text-align: left;
        width: 84%;
      }

      .encasing {
        height: 100%;
        overflow: hidden;
        width: 100%;
      }


      .slides {
        .clearfix();
        left: 0;
        list-style: none;
        padding: 0;
        position: relative;

        li {
          float: left;
          padding: 0 10px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      img {
        display: block;
        width: 100%;
      }

      a.view-all-pros {
        color: #fff;
        text-decoration: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 13px;
        font-family: @fontHeading;

        &:hover {
          text-decoration: none;
        }

        span {
          .transform(translateY(-50%));
          display: block;
          position: relative;
          text-align: center;
          top: 38%;
        }
      }

      // shadow below the image. needs to be on this because psuedo elements can't be on img tags
      h4:before {
        background: transparent url(~img/partials/homepage/slideshow-shadow.png) no-repeat center top;
        background-size: 100% auto;
        height: 36px;
        width: 100%;
        position: relative;
        content: '';
        display: block;
        top: 0px;
      }

      h4, h5 {
        text-align: center;
      }

      h4 {
        color: #37393d;
        font-size: 14px;
      }

      h5 {
        color: #7a7a7f;
        font-size: 11px;
        margin-bottom: 10px;
      }

      // page toggles
      .prev, .next {
        background: none;
        cursor: pointer;
        height: 26px;
        position: absolute;
        top: 22%;
        width: 26px;

        &:hover{
          .icon path {
            fill: #fff;
          }

          &:after {
            border-color: #aaa;
          }
        }

        &:after {
          .transition(all, 0.22s, ease-out);
          .border-radius(50%);
          border: 26px solid #bfbfc0;
          height: 52px;
          width: 52px;
          position: relative;
          content: '';
          display: block;
          z-index: 1010;
          top: -40px;
          left: -13px;
        }

        .icon {
          width: 20px;
          height: 20px;
          z-index: 1020;

          path {
            .transition(all, 0.22s, ease-out);
            fill: #fff;
          }
        }

        &.prev {
          left: -6%;
          .transform(rotateZ(90deg));
        }

        &.next {
          transform: rotateZ(-90deg);
          -webkit-transform: rotateZ(-90deg);
          right: -6%;
        }

        &.disabled,
        &:hover.disabled {
          cursor: default;

          .icon path {
            fill: #fff;
          }
          &:after {
            border-color: #eee;
          }
        }
      }

      // page indiciators
      .pages {
        list-style: none;
        text-align: center;
        padding: 20px 0 0;

        li {
          color: #c4c4c4;
          display: inline-block;
          font-size: 14px;
          margin: 0 2px;
          width: 10px;

          &.current {
            color: #7c7b7b;
            font-size: 26px;
            position: relative;
            left: -2px;
            top: 4px;
          }
        }
      }
    }
  }



  section.flexible-viewing {
    background: black;
    text-align: center;

    .container {
      background: black;
      padding: 96px 0 120px;
      z-index: 2000;
    }

    h2 {
      .fade-in-hr-setup(#cdcfd3);
      color: #fff;
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 60px;
      font-family: @fontNowayMedium;
    }

    hr {
      margin: 30px 0;
    }

    p {
      color: #79808c;
      margin: 0 auto 60px;
      max-width: 460px;
      width: 42%;
      font-family: @fontOpenSans;
    }

    .equation {
      color: white;
      font-family: @fontNowayMedium;

      .product {
        color: #cf0f1b;
      }

      .multiplicand, .multiplier, .product {
        display: inline-block;
        position: relative;

        > span {
          display: block;
          font-size: 106px;
          font-weight: 700;
          text-align: center;
        }

      }

      .connector {
        border-left: 1px solid #464a4e;
        display: block;
        height: 30px;
        margin-bottom: 7px;
        visibility: hidden;
      }

      .info {
        color: #79808c;
        position: absolute;
        font-size: 11px;
        left: 6px;
        top: -40px;
        text-align: left;
        width: 120%;
        min-width: 200px;
        font-family: @fontNowayMedium;
        letter-spacing: .1em;
      }

      .sign {
        display: inline-block;
        font-size: 106px;
        font-weight: 700;
      }
    }
  }



  section.video-info {
    background: rgba(153, 14, 21, 0.08);
    position: relative;

    // top arrow
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(153, 14, 21, 0);
      border-bottom-color: #990e15;
      border-width: 14px;
      margin-left: -14px;
    }

    .container {
      background: #990e15 url(~img/components/icon/videoinfobg.svg) no-repeat center center;
      -moz-background-size: cover;
      -o-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
      padding: 80px 0;
    }

    ul {
      list-style: none;
      margin: 0 20%;
    }

    .icon {
      height: 46px;
      width: 46px;
    }

    h2 {
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      padding: 14px 0;
    }

    p {
      color: #e9c7c9;
      font-size: 14px;
    }
  }



  section.video-sync {
    background: rgba(241, 241, 241, 0.28);

    .container {
      .clearfix();
      background: #f1f1f1 url(~img/partials/homepage/video-sync.jpg) no-repeat 95% top;
      background-size: auto 100%;
      padding: 0;
    }

    img {
      float: right;
      margin-right: 4%;
      width: 54%;
    }

    .info {
      position: relative;
      padding: 100px 0 90px 8%;
      width: 42%;
    }

    h2 {
      color: #37393d;
      font-size: 20px;
      font-weight: 500;
      padding: 0 0 14px;
    }

    p {
      color: #5e6367;
      font-size: 16px;
      padding: 0 5% 30px 0;
    }

    a {
      .border-radius(4px);
      .transition(all, 0.2s, ease-out);
      cursor: pointer;
      background: #37393d;
      color: #fff;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      padding: 18px 22px;
      text-decoration: none;

      &:hover {
        .border-radius(8px);
        background: #222;
      }
    }
  }



  section.active-community {
    background: rgba(255, 255, 255, 0.38);

    &.portugese {
      .container {
        padding: 80px 0 0 0;
      }
    }

    .container {
      background-color: #fff;
      padding: 84px 0 116px;
      text-align: center;
    }

    .header-icon {
      display: inline-block;
      margin-bottom: 14px;
      width: 24px;
    }

    h2 {
      .fade-in-hr-setup(#cdcfd3);
      color: #37393d;
      font-weight: 500;
      font-size: 38px;
      margin-bottom: 110px;
    }

    .rewards, .feed {
      .clearfix();
      margin: 0 8%;
      text-align: left;

      img {
        width: 50%;
      }
    }

    > div {
      min-height: 370px;
    }

    .rewards {
      background: transparent url(~img/partials/homepage/community-rewards.jpg) no-repeat 16% top;
      background-size: auto 70%;
      padding: 50px 0 80px 52%;
    }

    .feed {
      background: transparent url(~img/partials/homepage/community-feed.jpg) no-repeat 88% top;
      background-size: auto 100%;
      padding: 50px 50% 0 0;
    }

    h3 {
      color: #37393d;
      font-size: 20px;
      font-weight: 500;
      padding: 0 0 14px;
    }

    p {
      color: #5e6367;
      font-size: 16px;
      padding: 0 5% 30px 0;
      font-family: @fontOpenSans;
    }
  }



  #footer .container {
    margin: 0 auto;
    max-width: 1660px;
  }
}


// large monitors or iPad
@media (min-width: 1440px),
      only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: @widthTabletMin),
      only screen and (   -o-min-device-pixel-ratio: 3/2) and (min-width: @widthTabletMin),
      only screen and (        min-resolution: 192dpi) and (min-width: @widthTabletMin),
      only screen and (        min-resolution: 2dppx) and (min-width: @widthTabletMin) {
  body.homepage {
    section.intro {
      .container .phil {
        background-image: url(~img/partials/homepage/intro-phil-2x.jpg);
      }
    }

    section.change-your-game {
      .sample-video .example-video {
        background-image: url(~img/partials/homepage/video-fan-new.jpg);
      }
    }

    section.video-sync {
      .container {
        background-image: url(~img/partials/homepage/video-sync-2x.jpg);
      }
    }

    section.active-community {
      .rewards {
        background-image: url(~img/partials/homepage/community-rewards-2x.jpg);
        background-size: auto 70%;
      }

      .feed {
        background-image: url(~img/partials/homepage/community-feed-2x.jpg);
        background-size: auto 100%;
      }
    }
  }
}

@media (min-width: @widthTabletMin) and (max-width: @widthTabletPortrait) {
  body.homepage {
    section.intro .container {
      .phil {
        min-height: 436px;
      }

      .container {
        padding: 4%;
      }

      .info {
        width: 418px;
      }

      h1 {
        font-size: 46px;
      }

      p {
        font-size: 16px;
      }
    }

    section.change-your-game {
      .container {
        padding: 64px 0 0;
        &.portugese {
          img {
            padding-bottom: 0px;
          }
        }
      }

      .info {
        float: none;
        width: 58%;
        margin: 50px auto 0;
      }

      .sample-video {
        float: none;
        right: 0%;
        width: 74%;
        margin: 0 auto;
      }
    }

    section.flexible-viewing {
      .equation {
        .multiplicand,
        .multiplier, .product {
          > span {
            font-size: 85px;
          }
        }
        .sign {
          font-size: 85px;
        }
      }
    }

    section.video-info ul {
      margin: 0 3%;

      p {
        font-size: 13px;
      }
    }

    section.video-sync .container {
      background-size: 40% auto;
      background-position: center top;
      padding-top: 260px;

      .info {
        padding: 90px 0 40px 8%;
        width: 60%;
        margin: 0 auto;
      }
    }

    section.active-community {
      h2 {
        margin-bottom: 50px;
      }

      .rewards {
        background-size: auto 40%;
        background-position: center left;
        padding: 50px 0 0px 40%;
      }

      .feed {
        background-position: 100% center;
        background-size: auto 58%;
        padding: 50px 40% 0 0;
      }

      .rewards, .feed {
        margin: 0 4%;
      }

      p {
        font-size: 14px;
      }
    }
  }
}



@media (min-width: @widthTabletMin) {
  body.homepage {
    section.video-info {
      li {
        .transform(rotateZ(8deg));
        .transition(all, 0.3s, ease-out);
        display: inline-block;
        opacity: 0;
        padding: 0 0 0 4%;
        position: relative;
        left: 10px;
        width: 46%;

        &.playlist {
          .transition-delay(0.2s);
        }

        &.daily {
          .transition-delay(0.4s);
        }

        &.highlights {
          .transition-delay(0.6s);
        }
      }

      &.in-viewport {

        li {
          .transform(rotateZ(0deg));
          left: 0;
          opacity: 1;
        }
      }
    }
  }
}


@media (max-width: @widthMobileMax) {

  body.homepage.forum-menu-open {
    .nav-main {
      > .mobile-forum-hamburger {
        float: none;
        position: absolute;
        top: 26px;
        right: -140px;
      }
    }
  }

  body.homepage {
    padding-top: 0;

    .nav-main {
      background: none;
      height: 0px;
      position: absolute;

      > .logo {
        background: transparent url(~img/account/logo.png) no-repeat left top;
        background-size: 100% 100%;
        height: 60px;
        top: 15px;
        left: 45px;
        width: 60px;
      }

      > .logged_out-actions {
        display: none;
      }

      > .runitonce-poker.logged--out {
        font-size: 12px;
        right: 80px;
        top: 20px;
      }

      > .mobile-forum-hamburger {
        float: none;
        position: absolute;
        right: 20px;
        top: 26px;

        path {
          stroke: #fff;
        }

        h2 {
          color: #fff;
        }
      }
    }

    #body_container {
      width: 100%;
    }

    p {
      font-size: 14px;
    }

    section {
      width: 100%;
    }


    section.intro .container {
      height: 100%;

      .phil {
        min-height: 360px;
        height: 100%;
        background-position: 76% center;
      }

      .info {
        left: 15px;
        top: 110px;
        width: auto;

        a.russian-plans-pricing {
          position: relative;
          top: 0px;
          right: 0px;
          display: inline-block;
          margin-top: 15px;
        }
      }

      h1 {
        width: 60%;
        font-size: 32px;
        line-height: 1.2;
      }

      p {
        color: #bbb;
        font-size: 16px;
        line-height: 1.6;
        width: 80%;
      }

      .signature {
        display: none;
      }

      .login, .plans-pricing {
        display: inline-block !important;
        font-size: 13px;
        padding: 10px 0;
        width: 44%;
        text-align: center;
        white-space: nowrap;

        span {
          display: inline;
        }
      }

      .plans-pricing {
        margin-right: 20px;
        .arrow-bg {
          display: none;
        }
      }

      .login {
        background: #656b72;

        &:hover {
          background: #5b6167;
        }
      }
    }


    section.change-your-game .container {
      padding: 24px 0;

      &.portugese {
        img {
          padding-bottom: 0px;
          min-width: 280px;
        }
      }

      // DO SHOW SAMPLE VIDEO NOW ON MOBILE
      .inner {
        h2 {
          font-size: calc(35px + 35 * ((100vw - 400px) / 1040));
          margin-bottom: 60px;
        }

        .sample-video {
          clear: both;
          display: block;
          width: 100%;
          padding: 0;
          right: 0;

          .example-video {
            background-size: cover;
            min-height: 160px;
          }

          .toggle-intro-video {
            width: 100%;
          }

          .video-trigger {
            background-size: auto 100%;
            height: 110px;
          }
        }

        .info {
          float: none;
          display: block;
          width: 100%;
          padding: 40px 20px 40px 20px;
          text-align: center;
          margin: 0 auto;
        }

      }

      &.russian {
        .inner {
          display: block;

          > .info {
            width: 80%;
          }
        }
      }

      &.portugese {
        .inner {
          display: block;

          > .info {
            width: 80%;
          }
        }
      }


      .toughest-pros {
        background: none;
        padding: 0 0 40px;
        margin-top: 10px;

        &.russian {
          margin-top: -250px;  // do not leave blank gaps on the russian landing
        }

        &.portugese {
          .count {
             background-size: cover;
             background-image: url(~img/partials/homepage/portugese-number-bg.jpg);
             background-position: center;
             text-align: center;
             -webkit-background-clip: text;
             -webkit-text-fill-color: transparent;
             font-size: 72px;
          }
        }

        h2 {
          font-size: 20px;

          .count {
            font-size: 72px;
            font-family: @fontNowayMedium;
          }
        }

        p {
          font-size: 14px;
          line-height: 1.5;
          padding: 0;
          width: 80%;
          margin-bottom: 20px;
        }

        .slideshow {
          > div {
            text-align: center;
          }

          .encasing {
            margin-bottom: 20px;
          }

          .prev, .next {
            margin-bottom: 30px;
            position: relative;
          }

          .prev {
            left: -10%;
          }

          .next {
            right: -10%;
          }

          .pages {
            display: none;
          }
        }

        a.see-roster {
          display: inline-block;
        }
      }
    }


    section.flexible-viewing {
      // display: none;
      .container {
        padding: 60px 0 100px;
        z-index: 2000;
      }

      h2 {
        font-size: calc(40px + 40 * ((100vw - 400px) / 1040));
        margin-bottom: 60px;
      }

      p {
        max-width: 460px;
        width: 80%;
        margin-bottom: 20px;
      }

      .equation {
        .multiplicand, .multiplier, .product {
          > span {
            font-size: calc(45px + 30 * ((100vw - 400px) / 350));
          }
        }

        .info {
          position: absolute;
          font-size: 10px;
          left: 6px;
          bottom: -5px;
          text-align: left;
          letter-spacing: 0;

          &.long {
            min-width: 165px;
            top: calc(-55px + 19 * ((100vw - 400px) / 350));
            left: calc(-10px + 15 * ((100vw - 400px) / 350));
          }
        }

        .sign {
          font-size: calc(55px + 55 * ((100vw - 400px) / 1040));
        }
      }
    }

    section.video-info {
      &:after {
        display: none;
      }

      .container {
        background-size: auto 100%;
        background-position: 96% center;
        padding: 40px 0 0;
      }

      li {
        text-align: center;
        padding-bottom: 50px;
      }
    }

    section.video-sync {
      .container {
        background-size: 58% auto;
        background-position: center top;
      }

      .info {
        width: auto;
        padding: 82% 8% 50px;
      }

      h2 {
        font-size: 17px;
        width: 54%;
      }

      p {
        font-size: 14px;
        line-height: 1.5;
      }
    }


    section.active-community {
      &.portugese {
        display: none;
      }
      .container {
        padding: 50px 0 0;
      }

      h2 {
        font-size: 20px;
        margin-bottom: 70px;
      }

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        line-height: 1.5;
      }

      .rewards {
        padding: 210px 8% 0;
        background-size: 60% auto;
        background-position: center top;
        margin: 0;
      }

      .feed {
        display: none;
      }
    }

  }
}



// Tablets
@media (max-width: @widthTabletMax) {
  body.homepage {
    // because of iOS scrolling we have to drop the parallax
    #torso {
      -webkit-perspective: 0;
      perspective: 0;

      .phil-bg, .base {
        .transform(none);
      }
    }
  }
}


// Mobile Landscape
@media (min-width: @widthMobilePortrait) and (max-width: @widthMobileMax) {
  body.homepage {
    section.intro {
      .container h1 {
        width: auto;
      }
      .container .info {
        top: 90px;
      }
      .container p {
        margin-bottom: 12px;
      }
      .take-a-tour {
        bottom: 0px;
      }
    }
  }
}


// Mobile Portrait
@media (max-width: @widthMobilePortrait) {
  body.homepage {
    section.video-sync .container {
      background-size: 96% auto;
    }


    section.active-community {
      .rewards {
        background-size: 104% auto;
        background-position: 20px top;
      }
    }
  }
}


@media (max-width: @widthMobileMax) {
  // do show video iframe (from vimeo on the homepage)
  // adequately on our homepage
  body.homepage {
    .rio-modal {
      .content {
        width: 80%;
        height: auto;
      }
    }
  }
}
