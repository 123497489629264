@font-face {
  font-family: 'nowaylight';
  src: url('~fonts/noway-light-webfont.eot');
  src: url('~fonts/noway-light-webfont.eot?#iefix') format('embedded-opentype'), url('~fonts/noway-light-webfont.woff2') format('woff2'), url('~fonts/noway-light-webfont.woff') format('woff'), url('~fonts/noway-light-webfont.ttf') format('truetype'), url('~fonts/noway-light-webfont.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'nowayregular';
  src: url('~fonts/noway-regular-webfont.eot');
  src: url('~fonts/noway-regular-webfont.eot?#iefix') format('embedded-opentype'), url('~fonts/noway-regular-webfont.woff2') format('woff2'), url('~fonts/noway-regular-webfont.woff') format('woff'), url('~fonts/noway-regular-webfont.ttf') format('truetype'), url('~fonts/noway-regular-webfont.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'nowaymedium';
  src: url('~fonts/noway-medium-webfont.eot');
  src: url('~fonts/noway-medium-webfont.eot?#iefix') format('embedded-opentype'), url('~fonts/noway-medium-webfont.woff2') format('woff2'), url('~fonts/noway-medium-webfont.woff') format('woff'), url('~fonts/noway-medium-webfont.ttf') format('truetype'), url('~fonts/noway-medium-webfont.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'nowaybold';
  src: url('~fonts/noway-bold-webfont.eot');
  src: url('~fonts/noway-bold-webfont.eot?#iefix') format('embedded-opentype'), url('~fonts/noway-bold-webfont.woff2') format('woff2'), url('~fonts/noway-bold-webfont.woff') format('woff'), url('~fonts/noway-bold-webfont.ttf') format('truetype'), url('~fonts/noway-bold-webfont.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*
/* Resets and rendering of default elements
/* -------------------------------------------------------------------------- */
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
form,
fieldset,
textarea,
hr,
button {
  margin: 0px;
  padding: 0px;
}
header,
section,
aside,
article {
  display: block;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  background: #f1f3f5;
  background-size: 74px 74px;
  color: #616262;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-weight: 400;
}
#body_container {
  transition: all 0.2s ease-in-out;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  position: relative;
  right: 0;
  z-index: 510;
}
#torso {
  flex: 50;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-preferred-size: auto;
  margin-top: 50px;
}
@media (max-width: 767px) {
  body {
    background: #f1f3f5;
    overflow-x: hidden;
  }
  body #torso {
    transition: all 0.1s ease-in-out;
    -webkit-transition: -webkit-filter 0.1s linear;
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
    /* Safari */
    border-right: none;
    margin-top: 45px;
  }
}
fieldset {
  border: none;
}
textarea {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
img {
  border: none;
}
a {
  color: #a81313;
  text-decoration: none;
}
a:hover {
  color: #b61e22;
  text-decoration: underline;
}
p,
ul,
ol {
  margin: 0;
  padding: 0 0 16px 0;
}
blockquote {
  margin-left: 3em;
  font-style: italic;
  color: #777;
}
::-moz-selection {
  background: #1b1e1f;
  color: #fff;
}
::selection {
  background: #1b1e1f;
  color: #fff;
}
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  position: relative;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.hidden {
  display: none;
  visibility: hidden;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide {
  display: none !important;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
@media (max-width: 767px) {
  .visible-phone {
    display: inherit !important;
  }
  .hidden-phone {
    display: none !important;
  }
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-phone {
    display: none !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .hidden-desktop {
    display: none !important;
  }
  .visible-desktop {
    display: inherit !important;
  }
}
/*
  Use separate background images for retina and standard screens.

  Example Usage:
    Regular image path: /images/sprite.png
    Retina image path:  /images/sprite@2x.png

    #element{
      .retina-background-image('/images/sprite','png', no-repeat, 100px, auto, -10px, 0);
    }
*/
.center-vertically-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center-horizontally-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.center-vertically-and-horizontally-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-horizontally-relative {
  position: relative;
  margin: 0 auto;
}
/*

Baseline styles for user avatars. (sized for a 40px image) The display name is optional but the rest needs to stay.

Here is some example markup:

Django:

<a href="/users/{{ object.author.username }}/" rel="author" class="author" title="View user's profile">
  <span class="avatar">
    <img height="36px" width="auto" src="{{ object.author.get_avatar }}" alt="user avatar">
    {% if object.author.is_elite_pro %}<span class="user_level icon-pro is_pro">{{ icons.pro|safe }}</span>
    {% elif object.author.is_pro %}<span class="user_level icon-pro is_pro">{{ icons.pro|safe }}</span>
    {% elif object.author.is_rio_staff %}<span class="user_level icon-pro is_staff">{{ icons.staff|safe }}</span>
    {% elif object.author.is_moderator %}<span class="user_level icon-mod is_mod">{{ icons.mod|safe }}</span>
    {% endif %}
  </span>
  <span class="display_name">{{ object.author.display_name }}</span>
</a>


Angular:

<a href="/users/{{ comment.author.username }}/" rel="author" class="author" title="View user's profile">
  <span class="avatar">
    <img height="36px" width="auto" src="{{ comment.author.avatar }}" alt="user avatar">
    <span ng-if="comment.author.is_elite_pro" class="user_level icon-pro is_pro">{% endverbatim %}{{ icons.pro|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_pro" class="user_level icon-pro is_pro">{% endverbatim %}{{ icons.pro|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_rio_staff && !comment.author.is_pro" class="user_level icon-pro is_staff">{% endverbatim %}{{ icons.staff|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_moderator && !comment.author.is_pro && !comment.author.is_rio_staff" class="user_level icon-mod is_mod">{% endverbatim %}{{ icons.mod|safe }}{% verbatim %}</span>
  </span>
</a>


*/
.avatar {
  position: relative;
  display: inline-block;
  line-height: 0;
}
.avatar img {
  border-radius: 3px;
  background-clip: padding-box;
  border: 1px solid #EAEAEA;
  background: #C8C8C8;
  display: block;
  line-height: 0;
  z-index: 1;
  color: #C8C8C8;
  font-size: 0;
}
.avatar .user_level {
  width: 50%;
  height: 50%;
  position: absolute;
  bottom: -10%;
  right: -10%;
  z-index: 2;
}
.avatar svg {
  width: 100%;
  height: 100%;
}
.btn {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border-radius: 20px;
  background-clip: padding-box;
  transition: all 0.1s ease-in-out;
  background-color: transparent;
  border: 1px solid #c0c7ca;
  color: #6a6f71;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 32px;
}
.btn:hover {
  transition: none 0.2s ease-out;
  text-decoration: none;
  border-color: #aaa;
}
.btn [class^="icon-"],
.btn [class*=" icon-"] {
  height: 23px;
  margin-right: 4px;
  position: relative;
  top: 6px;
  width: 23px;
}
.btn.contains_icon {
  padding: 8px 39px 14px;
}
.btn.btn-small {
  font-size: 11px;
  padding: 6px 16px;
}
.btn.btn-square {
  border-radius: 3px;
  background-clip: padding-box;
  background: #fff;
  border: 1px solid #b2babe;
  padding: 9px 30px;
  text-align: center;
}
.btn.btn-square:hover {
  border-color: #999;
}
.btn.btn-small {
  font-size: 11px;
  padding: 6px 16px;
}
.btn.btn-large {
  padding: 12px 20px 12px 20px;
}
.btn.btn-gray {
  background: #bac1c4;
  color: #fff;
}
.btn.btn-gray:hover {
  background: #B3BABE;
}
.btn.btn-dark-gray {
  background: #656b72;
  border: none;
  color: #fff;
}
.btn.btn-dark-gray:hover {
  background: #6e757c;
}
.btn.btn-black {
  background: #353535;
  border: none;
  color: #c2c2c2;
}
.btn.btn-black:hover {
  background: #3f3f3f;
}
.btn.btn-red {
  background: #90171c;
  border: none;
  color: #fff;
  border-radius: 3px;
  background-clip: padding-box;
}
.btn.btn-red:hover {
  background: #a2403f;
}
.btn.btn-popup {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  color: #1E1F21;
}
.btn.btn-popup:hover {
  opacity: 0.9;
}
.btn.btn-popup.btn-contrast {
  color: #ffffff;
  background: #1E1F21;
}
.btn.btn-popup.btn-disabled {
  color: #a5a5a5;
  background-color: #f1f4f6;
  pointer-events: none;
}
.btn.btn-poppins {
  font-size: 1em;
  line-height: 1.2em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border: none;
  border-radius: 6px;
  background-clip: padding-box;
  padding: 8px 40px;
  color: #1B1B1B;
  background: #fff;
}
.btn.btn-poppins:hover {
  text-decoration: none;
  opacity: 0.9;
}
.btn.btn-poppins.inverted {
  background: #1B1B1B;
  color: #fff;
}
@media (max-width: 767px) {
  body.site_message_showing #unsupported-browser,
  body.site_message_showing_feed #unsupported-browser,
  body.site_message_showing #site_message,
  body.site_message_showing_feed #site_message,
  body.site_message_showing #upgrade_notice,
  body.site_message_showing_feed #upgrade_notice {
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 768px) {
  body.site_message_showing .nav-main {
    top: 50px;
  }
  body.site_message_showing .nav-drawer {
    top: 85px;
  }
  body.site_message_showing .nav-drawer .nav-main-user {
    padding-bottom: 85px;
  }
  body.site_message_showing #torso {
    margin-top: 100px;
  }
  body.site_message_showing_feed #unsupported-browser,
  body.site_message_showing_feed #site_message,
  body.site_message_showing_feed #upgrade_notice {
    top: auto;
    bottom: 0;
  }
}
@media (max-width: 767px) {
  #unsupported-browser .span12,
  #site_message .span12,
  #upgrade_notice .span12 {
    margin-right: 8%;
  }
  #unsupported-browser i.close-message,
  #site_message i.close-message,
  #upgrade_notice i.close-message {
    z-index: 3000;
    background-repeat: no-repeat;
    right: 0;
    height: 50px;
    width: 50px;
  }
}
#site_message.discount-available .cta {
  transition: all 0.2s ease-in-out;
  background-color: #333;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  margin-left: 4px;
  padding: 11px 14px;
  text-decoration: none;
}
#site_message.discount-available .cta:hover {
  background: #333;
}
#unsupported-browser,
#site_message,
#upgrade_notice {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f6f6f6 url(components/~img/partials/site-message/banner-bg.jpg) repeat-x left top;
  background-size: auto 100%;
  left: 0;
  position: fixed !important;
  text-align: center;
  top: 0;
  transition: background-color 400ms ease;
  width: 100%;
  z-index: 8000 !important;
  min-height: 50px;
}
#unsupported-browser *,
#site_message *,
#upgrade_notice *,
#unsupported-browser *:before,
#site_message *:before,
#upgrade_notice *:before,
#unsupported-browser *:after,
#site_message *:after,
#upgrade_notice *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#unsupported-browser:before,
#site_message:before,
#upgrade_notice:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
#unsupported-browser .container,
#site_message .container,
#upgrade_notice .container,
#unsupported-browser .site-message__content,
#site_message .site-message__content,
#upgrade_notice .site-message__content {
  color: #858a90;
  display: inline-block;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 15px;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  padding: 1em;
}
#unsupported-browser .container strong,
#site_message .container strong,
#upgrade_notice .container strong,
#unsupported-browser .site-message__content strong,
#site_message .site-message__content strong,
#upgrade_notice .site-message__content strong {
  color: #1a1e1f;
}
#unsupported-browser a,
#site_message a,
#upgrade_notice a {
  color: #FFF;
  text-decoration: underline;
}
#unsupported-browser.order_by,
#site_message.order_by,
#upgrade_notice.order_by {
  display: block;
}
#unsupported-browser.show,
#site_message.show,
#upgrade_notice.show {
  display: block;
}
#unsupported-browser .close-message,
#site_message .close-message,
#upgrade_notice .close-message {
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 17px;
}
#unsupported-browser .close-message .icon,
#site_message .close-message .icon,
#upgrade_notice .close-message .icon {
  height: 16px;
  width: 16px;
}
#unsupported-browser .close-message path,
#site_message .close-message path,
#upgrade_notice .close-message path {
  fill: #1a1e1f;
}
@media (max-width: 1439px) {
  #unsupported-browser .site-message__content,
  #site_message .site-message__content,
  #upgrade_notice .site-message__content {
    font-size: calc(10px + 5 * ((100vw - 400px) / 1040));
  }
}
@media (max-width: 767px) {
  #unsupported-browser,
  #site_message,
  #upgrade_notice {
    text-align: left;
  }
  #unsupported-browser .annual-banner-close,
  #site_message .annual-banner-close,
  #upgrade_notice .annual-banner-close {
    z-index: 90;
    right: 0px;
    top: 10px;
    padding: 5px;
  }
  #unsupported-browser .site-message__content,
  #site_message .site-message__content,
  #upgrade_notice .site-message__content {
    line-height: 1.4;
    padding-left: 108px;
    position: fixed;
    margin: 5px;
  }
  #unsupported-browser .site-message__content .cta,
  #site_message .site-message__content .cta,
  #upgrade_notice .site-message__content .cta {
    font-size: calc(11px + 2 * ((100vw - 400px) / 1040));
    position: absolute;
    left: 10px;
    top: 0;
  }
  #unsupported-browser strong,
  #site_message strong,
  #upgrade_notice strong {
    display: block;
  }
}
#site_message {
  display: none;
}
.message_overlay {
  background: transparent;
  z-index: 1100;
  position: relative;
  width: 100%;
}
.message_overlay.hide_message_container_right .message_container_right {
  display: none;
}
.message_overlay .message_signup_error {
  background-color: #982d28;
  text-align: center;
  margin-bottom: -50px;
}
.message_overlay .message_signup_error .message_container {
  position: relative;
  margin-top: 52px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.message_overlay .message_signup_error .message_container .message {
  padding-left: 50px;
  padding-right: 50px;
}
.message_overlay .message_signup_error .message_container .message .paypal,
.message_overlay .message_signup_error .message_container .message .credit-card,
.message_overlay .message_signup_error .message_container .message .skrill {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.2px;
  display: none;
  padding: 0;
}
.message_overlay .message_signup_error .message_container .message.paypal .paypal {
  display: block;
}
.message_overlay .message_signup_error .message_container .message.skrill .skrill {
  display: block;
}
.message_overlay .message_signup_error .message_container .message.credit-card .credit-card {
  display: block;
}
.message_overlay .message_signup_error .message_container .close-message {
  color: #fff;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 30px;
  font-size: calc(35px + 6 * ((100vw - 320px) / 1330));
  padding: 1%;
}
.message_overlay .message_container_right {
  position: absolute;
  left: 50%;
  top: 105px;
}
.message_overlay .message_container_right .message_container {
  position: relative;
  left: -50%;
  background: #fff;
}
.message_overlay .message_container_right .message_container .message {
  border: 1px solid #cecece;
  border-radius: 3px;
  background-clip: padding-box;
  padding: 25px 50px 18px 50px;
  overflow: hidden;
  position: relative;
  min-width: 500px;
  max-width: 700px;
}
.message_overlay .message_container_right .message_container .message .message_list {
  list-style: none;
  padding: 0;
}
.message_overlay .message_container_right .message_container .message .message_list li {
  color: #808080;
  display: block;
  padding: 0 0 8px 0;
  text-align: center;
}
.message_overlay .message_container_right .message_container .message .close-message {
  background: transparent url(components/~img/rio/message_close.png) no-repeat left top;
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 0;
  text-indent: -9999px;
  top: 0;
  width: 24px;
}
#neck .message {
  color: #fff;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 20px 0;
  position: relative;
  text-align: center;
}
#neck .message .message_container {
  margin: 0 auto;
  width: 80%;
}
#neck .message .message_container a {
  color: #fff;
  text-decoration: underline;
}
#neck .message .close-message {
  background: transparent url(components/~img/rio/neck_message_close.png) no-repeat left top;
  cursor: pointer;
  display: block;
  height: 11px;
  position: absolute;
  right: 15px;
  text-indent: -9999px;
  top: 14px;
  width: 11px;
}
#neck .message.upgrade {
  box-shadow: inset 0 1px 0 1px rgba(100, 100, 100, 0.4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid #000;
  color: #f5da5e;
  background: #202020;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-weight: 500;
  font-size: 15px;
}
#neck .message.upgrade:after {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 49px;
  width: 100%;
  z-index: 0;
}
#neck .message.upgrade strong {
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-weight: normal;
}
#neck .message.upgrade .btn-elite {
  color: #756601;
  margin-left: 11px;
  margin-right: 11px;
  text-decoration: none;
  width: 86px;
}
#neck .message.upgrade .btn-elite:hover {
  background-color: transparent;
}
#neck .message.upgrade .close-message {
  background: transparent url(components/~img/video/upgrade_close_btn.png) no-repeat left top;
  height: 12px;
  right: 9px;
  top: 9px;
  width: 12px;
}
#neck #upgrade_notice::before {
  content: None;
}
#neck #upgrade_notice .message_container {
  color: #000;
}
#neck #upgrade_notice .message_container a {
  color: #a81313;
}
#neck #upgrade_notice .message {
  position: static;
}
#neck #upgrade_notice .message .close {
  background: transparent url(components/~img/rio/message_close.png) no-repeat left top;
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 0;
  text-indent: -9999px;
  top: 0;
  width: 24px;
}
.dominate-with-data-course-banner {
  transition: "top background" 0.7s ease-in-out;
  height: 70px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 6400;
  justify-content: center;
  align-content: center;
  top: -20vh;
  background-color: #000;
  background-image: url("components/~img/courses/dominate-with-data/banner/freenachos.png"), url("components/~img/courses/dominate-with-data/banner/bg.jpg");
  background-position: 30px top, center top;
  background-size: auto 70px, cover;
  background-repeat: no-repeat;
  color: #fff;
}
.dominate-with-data-course-banner:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.dominate-with-data-course-banner:hover .dominate-with-data-course-banner__content span.out-now span {
  transform: translateX(5px);
}
.dominate-with-data-course-banner__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: min(40px, 2vw);
}
.dominate-with-data-course-banner__content span img {
  display: block;
  height: 35px;
}
.dominate-with-data-course-banner__content span.out-now {
  font-family: 'Open Sans', sans-serif;
  color: #00FFA3;
  font-size: 26px;
  text-transform: uppercase;
  margin-left: 5px;
}
.dominate-with-data-course-banner__content span.out-now span {
  display: inline-block;
}
.dominate-with-data-course-banner__close {
  color: #fff;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: calc(22px + 6 * ((100vw - 320px) / 1330));
  position: absolute;
  right: 2%;
  top: 12px;
  z-index: 6500;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1285px) {
  .dominate-with-data-course-banner {
    background-image: url("components/~img/courses/dominate-with-data/banner/freenachos.png"), url("components/~img/courses/dominate-with-data/banner/bg.jpg");
    background-position: -15px top, center top;
  }
  .dominate-with-data-course-banner__content {
    margin-left: 50px;
  }
  .dominate-with-data-course-banner__content span img {
    display: block;
    height: 20px;
  }
  .dominate-with-data-course-banner__content span.out-now {
    font-size: 20px;
  }
}
@media (max-width: 1030px) {
  .dominate-with-data-course-banner__content span.by {
    display: none;
  }
}
@media (max-width: 845px) {
  .dominate-with-data-course-banner__content {
    flex-direction: column;
    gap: 0;
  }
  .dominate-with-data-course-banner__content span.build-your img {
    height: 25px;
  }
  .dominate-with-data-course-banner__content span.title img {
    height: 20px;
  }
  .dominate-with-data-course-banner__content span.out-now {
    display: none;
  }
}
@media (max-width: 670px) {
  .dominate-with-data-course-banner__content span.title img {
    height: 10px;
  }
}
.rio-modal.rio-modal-newsletter-signup {
  overflow: auto;
}
.rio-modal.rio-modal-newsletter-signup .content {
  width: 720px !important;
  height: auto !important;
}
.rio-modal.rio-modal-newsletter-signup .close-message {
  display: block !important;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal {
  background: #12171f url('components/~img/partials/newsletter-signup-modal/newsletter-signup-modal-bg.jpg') no-repeat;
  background-size: 100%;
  border-radius: 30px;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap {
  color: #fff;
  min-height: 440px;
  padding: 235px 0 5px;
  margin: 0 auto;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap .modal-title {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 1em;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap p {
  margin: 10px auto 25px;
  padding: 0;
  width: 430px;
  color: #9d9faa;
  font-size: 18px;
  line-height: 1.7em;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap form {
  margin: 20px 10%;
  position: relative;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap form .input-email {
  color: #7d868e;
  background: #343a3f;
  border: 1px solid #343a3f;
  border-radius: 40px;
  font-size: 17px;
  padding: 20px;
  width: 100%;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap form .input-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  cursor: pointer;
  border: none;
  outline: none;
  background: #12171f;
  color: white;
  position: absolute;
  top: 10px;
  font-size: 17px;
  right: 10px;
  padding: 12px 20px;
  border-radius: 31px;
  transition: transform 0.2s ease-out;
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap form .input-submit:hover {
  transform: scale(1.1);
}
.rio-modal.rio-modal-newsletter-signup .newsletter-signup-modal .content-wrap form .input-submit:active {
  background: #333;
}
@media (max-width: 915px) {
  .rio-modal-newsletter-signup.active .content {
    overflow: hidden;
    width: 90% !important;
    max-width: 480px;
    height: auto !important;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap {
    padding-top: 125px;
    min-height: auto;
    width: auto;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap .modal-title {
    font-size: 42px;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap p {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
    width: auto;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap form {
    margin: 20px 5% 0;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap form .input-submit {
    font-size: 14px;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap form .input-email {
    font-size: 14px;
  }
  .rio-modal-newsletter-signup.active .newsletter-signup-modal .content-wrap form .form-errors p {
    margin-bottom: 0;
    padding-bottom: 5px;
  }
}
.rio-modal.rio-signup-survey-popup {
  overflow: auto;
}
.rio-modal.rio-signup-survey-popup .content {
  width: 720px !important;
  height: auto !important;
}
.rio-modal.rio-signup-survey-popup .close-message {
  display: block !important;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup {
  background: #12171f url('components/~img/partials/newsletter-signup-modal/newsletter-signup-modal-bg.jpg') no-repeat;
  background-size: 100%;
  border-radius: 30px;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup .content-wrap {
  color: #fff;
  min-height: 440px;
  padding: 175px 0 5px;
  margin: 0 auto;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup .content-wrap .modal-title {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 1em;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup .content-wrap p {
  margin: 10px auto 10px;
  padding: 0;
  width: 430px;
  color: #9d9faa;
  font-size: 18px;
  line-height: 1.7em;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup .content-wrap .actions {
  width: auto;
  margin-bottom: 25px;
}
.rio-modal.rio-signup-survey-popup .signup-survey-popup .content-wrap .actions a {
  color: #fff;
  font-size: 25px;
  line-height: 2.5em;
  padding: 30px;
}
@media (max-width: 915px) {
  .rio-signup-survey-popup.active .content {
    overflow: hidden;
    width: 90% !important;
    max-width: 480px;
    height: auto !important;
  }
  .rio-signup-survey-popup.active .signup-survey-popup .content-wrap {
    padding-top: 85px;
    min-height: auto;
    width: auto;
  }
  .rio-signup-survey-popup.active .signup-survey-popup .content-wrap .modal-title {
    font-size: 42px;
  }
  .rio-signup-survey-popup.active .signup-survey-popup .content-wrap p {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
    width: auto;
  }
  .rio-signup-survey-popup.active .signup-survey-popup .content-wrap .actions {
    width: auto;
    margin-bottom: 25px;
  }
  .rio-signup-survey-popup.active .signup-survey-popup .content-wrap .actions a {
    color: #fff;
    font-size: 20px;
    padding: 10px 10px;
  }
}
#footer {
  *zoom: 1;
  background: #090b12;
  padding: 66px 0;
  width: 100%;
  position: relative;
  z-index: 501;
}
#footer:before,
#footer:after {
  display: table;
  content: "";
}
#footer:after {
  clear: both;
}
#footer:before,
#footer:after {
  display: table;
  content: "";
}
#footer:after {
  clear: both;
}
#footer *,
#footer *:before,
#footer *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#footer .footer-content {
  display: flex;
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
}
#footer header {
  display: flex;
}
#footer .logo {
  display: inline-block;
  background: url("partials/~img/pages/elite-landing/logo.webp");
  background-size: cover;
  min-width: calc(0.7 * 30px);
  min-height: 30px;
  margin-right: 16px;
}
#footer h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7c8490;
  align-self: center;
}
#footer .links {
  float: right;
  vertical-align: middle;
  text-align: right;
  display: block;
}
#footer .links > section {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  min-width: 180px;
}
#footer .links > section:nth-child(3) {
  min-width: auto;
}
#footer h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  padding-bottom: 28px;
}
#footer ul {
  list-style: none;
  padding: 0;
}
#footer ul a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #7c8490;
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 6px 0;
}
#footer ul a:hover {
  text-decoration: underline;
}
@media (max-width: 960px) {
  #footer .footer-content {
    flex-direction: column-reverse;
  }
  #footer header {
    margin-top: 100px;
    width: 100%;
    justify-content: center;
  }
  #footer .links {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}
@media (max-width: 767px) {
  #footer .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #footer .links > section {
    text-align: center;
  }
  #footer .links h3 {
    padding: 20px 0 4px 0;
  }
}
body.homepage {
  /* hide close button on video play on homagepage
  as users can click in the background to close it */
}
body.homepage .close-message {
  display: none;
}
body.homepage #torso {
  -webkit-overflow-scrolling: touch;
  margin-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
body.homepage .base {
  width: 100vw;
}
body.homepage .phil-bg {
  background: #131313 url(partials/~img/partials/homepage/intro_phil.jpg) no-repeat 80% top;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  height: 100vh;
  min-height: 746px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
body.homepage button {
  border: none;
  outline: none;
}
body.homepage hr {
  background: #cdcfd3;
  border: none;
  display: inline-block;
  height: 1px;
  width: 134px;
}
body.homepage p {
  color: #5e6367;
  font-size: 17px;
  line-height: 1.7;
  padding: 0;
}
body.homepage section .container {
  margin: 0 auto;
  max-width: 1660px;
}
body.homepage section.intro {
  overflow: hidden;
}
body.homepage section.intro .container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8%;
}
body.homepage section.intro .container .info {
  width: 478px;
  z-index: 1020;
}
body.homepage section.intro h1 {
  color: #fff;
  font-size: 96px;
  font-weight: 700;
  line-height: 1.1;
}
body.homepage section.intro p {
  color: #949494;
  font-size: 18px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  margin: 12px 0 30px;
  max-width: 60%;
}
body.homepage section.intro a {
  border-radius: 4px;
  background-clip: padding-box;
  transition: all 0.2s ease-out;
  cursor: pointer;
  display: inline-block;
  background: #cf0f1b;
  color: #fff;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 14px;
  padding: 18px 80px 18px 20px;
  text-decoration: none;
  border-radius: 30px;
  position: relative;
}
body.homepage section.intro a.plans-pricing {
  box-shadow: 3px 4px 39px 0px #cf0f1b;
}
body.homepage section.intro a span {
  display: none;
}
body.homepage section.intro a:hover {
  transform: scale(1.1);
}
body.homepage section.intro a .arrow-bg {
  background-image: url("partials/~img/partials/homepage/sign-up-arrow.png");
  width: 22px;
  height: 11px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 23px;
}
body.homepage section.intro .flag-wrapper {
  position: relative;
}
body.homepage section.intro .flags {
  position: absolute;
  margin: 30px 0;
  width: 125px;
  border: 1px solid #acb2bc;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow: hidden;
  padding: 0;
  background: white;
}
body.homepage section.intro .flags:hover {
  border-radius: 15px;
}
body.homepage section.intro .flags:hover li {
  display: flex !important;
  order: 2;
}
body.homepage section.intro .flags:hover li:hover {
  background-color: #f3f3f3;
}
body.homepage section.intro .flags:hover .dropdown_arrow {
  visibility: hidden;
}
body.homepage section.intro .flags li {
  justify-content: center;
  display: none;
}
body.homepage section.intro .flags a {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 125px;
  text-decoration: none;
  padding: 6px 10px;
  background: white;
}
body.homepage section.intro .flags a:hover {
  background-color: #acb2bc;
}
body.homepage section.intro .flags .dropdown_arrow {
  height: 6px;
  visibility: visible;
  display: inline-block;
}
body.homepage section.intro .flags .flag {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
}
body.homepage section.intro .flags li {
  order: 2;
}
body.homepage section.intro .flags li.english .flag {
  background-image: url("partials/~img/flags/usa.png");
}
body.homepage section.intro .flags li.russia .flag {
  background-image: url("partials/~img/flags/russia.png");
}
body.homepage section.intro .flags li.brazil .flag {
  background-image: url("partials/~img/flags/brazil.png");
}
body.homepage section.intro .flags.en-us li.english {
  display: flex;
  order: 1;
}
body.homepage section.intro .flags.ru li.russia {
  display: flex;
  order: 1;
}
body.homepage section.intro .flags.pt li.brazil {
  display: flex;
  order: 1;
}
body.homepage section.intro .signature {
  display: inline-block;
  width: 134px;
  top: 14px;
  position: relative;
  left: 28px;
}
body.homepage section.intro .take-a-tour {
  cursor: pointer;
  position: absolute;
  width: 100%;
  bottom: 12px;
  left: 0;
  display: none;
  text-align: center;
  z-index: 1020;
}
body.homepage section.intro .take-a-tour .icon {
  display: inline-block;
  height: 26px;
  margin-top: 30px;
  width: 26px;
}
body.homepage section.intro .take-a-tour .icon path {
  opacity: 0.2;
}
@keyframes pulsate-arrow-one {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.6;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes pulsate-arrow-one {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.6;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}
body.homepage section.intro .take-a-tour .icon .one {
  animation-name: pulsate-arrow-one;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: infinite;
}
body.homepage section.intro .take-a-tour .icon .two {
  animation-name: pulsate-arrow-one;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0.3s;
  animation-iteration-count: infinite;
}
body.homepage section.intro .take-a-tour .icon .three {
  animation-name: pulsate-arrow-one;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0.55s;
  animation-iteration-count: infinite;
}
body.homepage section.intro .take-a-tour h2 {
  color: #fff;
  font-size: 13px;
  margin: 8px 0;
}
body.homepage section.vision {
  background: #070910 url(partials/~img/partials/homepage/homepage_vision_section_bg-min.png) no-repeat top;
  background-size: auto 100%;
  max-height: 742px;
}
body.homepage section.vision .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body.homepage section.vision .inner h3 {
  background: url(partials/~img/visions/plo/vision-logo@2x-min.png) no-repeat center;
  height: 15vw;
  width: 90vw;
  max-height: 100px;
  max-width: 370px;
  background-size: auto 100%;
  color: transparent;
  margin-bottom: 20px;
  display: inline-block;
}
body.homepage section.vision .inner h4 {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}
body.homepage section.vision .inner p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #fff;
  padding: 50px 0;
}
body.homepage section.vision .inner ul {
  list-style: none;
}
body.homepage section.vision .inner ul li {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #fff;
  margin-bottom: 15px;
}
body.homepage section.vision .inner ul li:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-right: 10px;
  background-image: url(partials/~img/misc/checkmark_circled_black.png);
  background-size: 100% 100%;
  filter: invert(43%) sepia(94%) saturate(3573%) hue-rotate(330deg) brightness(95%) contrast(115%);
}
body.homepage section.vision .inner .left {
  width: 40%;
  padding: 150px 50px 190px 110px;
}
body.homepage section.vision .inner .right {
  width: 60%;
}
body.homepage section.vision .inner .right .visions-video {
  background: transparent url(partials/~img/partials/homepage/vision-video-homepage.webp) no-repeat left top;
  background-size: auto 100%;
  display: block;
  min-height: 660px;
}
body.homepage section.vision .inner .right .visions-video:hover {
  cursor: pointer;
}
@media (max-width: 1155px) {
  body.homepage section.vision {
    max-height: none;
    background: #070910;
  }
  body.homepage section.vision .container {
    padding: 5%;
  }
  body.homepage section.vision .inner {
    flex-direction: column;
  }
  body.homepage section.vision .inner .left {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  body.homepage section.vision .inner .right {
    width: auto;
    padding: 0;
  }
  body.homepage section.vision .inner .right .visions-video {
    min-width: 90vw;
    min-height: calc(0.78 * 90vw);
  }
}
body.homepage section.change-your-game {
  background: #f6f8f9;
  text-align: center;
}
body.homepage section.change-your-game.dancing-bg .container {
  background-image: url(partials/~img/partials/homepage/portugese-dancing-bg.jpg);
  background-size: cover;
  background-position-y: 18%;
}
body.homepage section.change-your-game .container {
  *zoom: 1;
  background-color: #fff;
  padding: 84px 0 36px;
  position: relative;
}
body.homepage section.change-your-game .container:before,
body.homepage section.change-your-game .container:after {
  display: table;
  content: "";
}
body.homepage section.change-your-game .container:after {
  clear: both;
}
body.homepage section.change-your-game .container:before,
body.homepage section.change-your-game .container:after {
  display: table;
  content: "";
}
body.homepage section.change-your-game .container:after {
  clear: both;
}
body.homepage section.change-your-game .container.portugese img {
  padding-bottom: 85px;
  width: 45%;
  max-width: 645px;
}
body.homepage section.change-your-game .header-icon {
  display: inline-block;
  margin-bottom: 14px;
  width: 24px;
}
body.homepage section.change-your-game .container .inner > h2 {
  margin-bottom: 110px;
}
body.homepage section.change-your-game .container .inner > h2:after {
  position: relative;
  width: 134px;
  height: 2px;
  background: #cdcfd3;
  content: '';
  opacity: 1;
  display: block;
  margin: 0 auto;
  top: 30px;
}
body.homepage section.change-your-game h2 {
  color: #37393d;
  font-weight: 500;
  font-size: 38px;
  position: relative;
}
body.homepage section.change-your-game .sample-video {
  clear: both;
  float: right;
  position: relative;
  right: 2%;
  width: 64%;
}
body.homepage section.change-your-game .sample-video .example-video {
  background: transparent url(partials/~img/partials/homepage/video-fan-new.jpg) no-repeat left top;
  background-size: auto 100%;
  display: block;
  min-height: 355px;
  position: relative;
  width: 100%;
}
body.homepage section.change-your-game .sample-video .toggle-intro-video {
  cursor: pointer;
  width: 72%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
body.homepage section.change-your-game .sample-video .video-trigger {
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
  background: transparent url(partials/~img/partials/homepage/sample-video.png) no-repeat left top;
  background-size: auto 100%;
  bottom: -12px;
  cursor: pointer;
  height: 168px;
  left: 10px;
  position: absolute;
  width: 116px;
}
body.homepage section.change-your-game .info {
  float: left;
  text-align: left;
  width: 24%;
  margin-left: 8%;
}
body.homepage section.change-your-game h3 {
  color: #37393d;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 14px;
}
body.homepage .rio-modal {
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 8000;
}
body.homepage .rio-modal .bg {
  transition: none;
  background: rgba(30, 31, 33, 0.9);
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 8010;
}
body.homepage .rio-modal.active {
  visibility: visible;
}
body.homepage .rio-modal.active .bg {
  transition: all 0.25s ease-out;
  opacity: 1;
}
body.homepage .rio-modal .content {
  display: inline-block;
  transform: translateY(-50%);
  transform-origin: 0 0;
  margin: 0 auto;
  position: relative;
  top: 50%;
  visibility: hidden;
  z-index: 8020;
  width: 720px;
  height: 390px;
}
body.homepage .rio-modal .content.active {
  visibility: visible;
}
body.homepage .rio-modal iframe {
  display: block;
}
body.homepage .rio-modal .close {
  transition: opacity 0.22s ease-out;
  bottom: -28px;
  cursor: pointer;
  font-size: 11px;
  opacity: 0.6;
  position: absolute;
  right: 0;
  text-transform: uppercase;
}
body.homepage .rio-modal .close:hover {
  opacity: 1;
}
body.homepage .toughest-pros {
  background: #fff url(partials/~img/partials/homepage/video-divider.png) no-repeat center top;
  background-size: 66% auto;
  clear: both;
  padding: 74px 0 90px;
  width: 100%;
  display: block;
  margin-top: 100px;
  float: left;
}
body.homepage .toughest-pros.russian {
  margin-top: -100px;
}
body.homepage .toughest-pros.portugese .count {
  background-size: cover;
  background-image: url(partials/~img/partials/homepage/portugese-number-bg.jpg);
  background-position: center;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 98px;
  font-weight: 700;
  line-height: 1;
}
body.homepage .toughest-pros.portugese img {
  width: 40%;
  height: auto;
  margin: 30px auto;
  margin-bottom: 0;
}
body.homepage .toughest-pros h2 {
  margin-bottom: 60px;
}
body.homepage .toughest-pros h2:after {
  position: relative;
  width: 134px;
  height: 2px;
  background: #cdcfd3;
  content: '';
  opacity: 1;
  display: block;
  margin: 0 auto;
  top: 30px;
}
body.homepage .toughest-pros h2 .count {
  color: #95865e;
  display: block;
  font-size: 98px;
  line-height: 1;
  font-family: 'nowaylight', Verdana, Tahoma, sans-serif;
}
body.homepage .toughest-pros p {
  margin: 0 auto 60px;
  width: 42%;
  font-family: 'Open Sans', sans-serif;
}
body.homepage .toughest-pros img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
body.homepage .toughest-pros a.see-roster {
  display: none;
}
body.homepage .toughest-pros .slideshow {
  position: relative;
  opacity: 0;
  width: 100%;
}
body.homepage .toughest-pros .slideshow > div {
  display: inline-block;
  max-width: 1092px;
  margin: 0 auto;
  position: relative;
  text-align: left;
  width: 84%;
}
body.homepage .toughest-pros .slideshow .encasing {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
body.homepage .toughest-pros .slideshow .slides {
  *zoom: 1;
  left: 0;
  list-style: none;
  padding: 0;
  position: relative;
}
body.homepage .toughest-pros .slideshow .slides:before,
body.homepage .toughest-pros .slideshow .slides:after {
  display: table;
  content: "";
}
body.homepage .toughest-pros .slideshow .slides:after {
  clear: both;
}
body.homepage .toughest-pros .slideshow .slides:before,
body.homepage .toughest-pros .slideshow .slides:after {
  display: table;
  content: "";
}
body.homepage .toughest-pros .slideshow .slides:after {
  clear: both;
}
body.homepage .toughest-pros .slideshow .slides li {
  float: left;
  padding: 0 10px;
  position: relative;
}
body.homepage .toughest-pros .slideshow .slides li:last-child {
  margin-right: 0;
}
body.homepage .toughest-pros .slideshow img {
  display: block;
  width: 100%;
}
body.homepage .toughest-pros .slideshow a.view-all-pros {
  color: #fff;
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 13px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
}
body.homepage .toughest-pros .slideshow a.view-all-pros:hover {
  text-decoration: none;
}
body.homepage .toughest-pros .slideshow a.view-all-pros span {
  transform: translateY(-50%);
  transform-origin: 0 0;
  display: block;
  position: relative;
  text-align: center;
  top: 38%;
}
body.homepage .toughest-pros .slideshow h4:before {
  background: transparent url(partials/~img/partials/homepage/slideshow-shadow.png) no-repeat center top;
  background-size: 100% auto;
  height: 36px;
  width: 100%;
  position: relative;
  content: '';
  display: block;
  top: 0px;
}
body.homepage .toughest-pros .slideshow h4,
body.homepage .toughest-pros .slideshow h5 {
  text-align: center;
}
body.homepage .toughest-pros .slideshow h4 {
  color: #37393d;
  font-size: 14px;
}
body.homepage .toughest-pros .slideshow h5 {
  color: #7a7a7f;
  font-size: 11px;
  margin-bottom: 10px;
}
body.homepage .toughest-pros .slideshow .prev,
body.homepage .toughest-pros .slideshow .next {
  background: none;
  cursor: pointer;
  height: 26px;
  position: absolute;
  top: 22%;
  width: 26px;
}
body.homepage .toughest-pros .slideshow .prev:hover .icon path,
body.homepage .toughest-pros .slideshow .next:hover .icon path {
  fill: #fff;
}
body.homepage .toughest-pros .slideshow .prev:hover:after,
body.homepage .toughest-pros .slideshow .next:hover:after {
  border-color: #aaa;
}
body.homepage .toughest-pros .slideshow .prev:after,
body.homepage .toughest-pros .slideshow .next:after {
  transition: all 0.22s ease-out;
  border-radius: 50%;
  background-clip: padding-box;
  border: 26px solid #bfbfc0;
  height: 52px;
  width: 52px;
  position: relative;
  content: '';
  display: block;
  z-index: 1010;
  top: -40px;
  left: -13px;
}
body.homepage .toughest-pros .slideshow .prev .icon,
body.homepage .toughest-pros .slideshow .next .icon {
  width: 20px;
  height: 20px;
  z-index: 1020;
}
body.homepage .toughest-pros .slideshow .prev .icon path,
body.homepage .toughest-pros .slideshow .next .icon path {
  transition: all 0.22s ease-out;
  fill: #fff;
}
body.homepage .toughest-pros .slideshow .prev.prev,
body.homepage .toughest-pros .slideshow .next.prev {
  left: -6%;
  transform: rotateZ(90deg);
  transform-origin: 0 0;
}
body.homepage .toughest-pros .slideshow .prev.next,
body.homepage .toughest-pros .slideshow .next.next {
  transform: rotateZ(-90deg);
  -webkit-transform: rotateZ(-90deg);
  right: -6%;
}
body.homepage .toughest-pros .slideshow .prev.disabled,
body.homepage .toughest-pros .slideshow .next.disabled,
body.homepage .toughest-pros .slideshow .prev:hover.disabled,
body.homepage .toughest-pros .slideshow .next:hover.disabled {
  cursor: default;
}
body.homepage .toughest-pros .slideshow .prev.disabled .icon path,
body.homepage .toughest-pros .slideshow .next.disabled .icon path,
body.homepage .toughest-pros .slideshow .prev:hover.disabled .icon path,
body.homepage .toughest-pros .slideshow .next:hover.disabled .icon path {
  fill: #fff;
}
body.homepage .toughest-pros .slideshow .prev.disabled:after,
body.homepage .toughest-pros .slideshow .next.disabled:after,
body.homepage .toughest-pros .slideshow .prev:hover.disabled:after,
body.homepage .toughest-pros .slideshow .next:hover.disabled:after {
  border-color: #eee;
}
body.homepage .toughest-pros .slideshow .pages {
  list-style: none;
  text-align: center;
  padding: 20px 0 0;
}
body.homepage .toughest-pros .slideshow .pages li {
  color: #c4c4c4;
  display: inline-block;
  font-size: 14px;
  margin: 0 2px;
  width: 10px;
}
body.homepage .toughest-pros .slideshow .pages li.current {
  color: #7c7b7b;
  font-size: 26px;
  position: relative;
  left: -2px;
  top: 4px;
}
body.homepage section.flexible-viewing {
  background: black;
  text-align: center;
}
body.homepage section.flexible-viewing .container {
  background: black;
  padding: 96px 0 120px;
  z-index: 2000;
}
body.homepage section.flexible-viewing h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 60px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
}
body.homepage section.flexible-viewing h2:after {
  position: relative;
  width: 134px;
  height: 2px;
  background: #cdcfd3;
  content: '';
  opacity: 1;
  display: block;
  margin: 0 auto;
  top: 30px;
}
body.homepage section.flexible-viewing hr {
  margin: 30px 0;
}
body.homepage section.flexible-viewing p {
  color: #79808c;
  margin: 0 auto 60px;
  max-width: 460px;
  width: 42%;
  font-family: 'Open Sans', sans-serif;
}
body.homepage section.flexible-viewing .equation {
  color: white;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
}
body.homepage section.flexible-viewing .equation .product {
  color: #cf0f1b;
}
body.homepage section.flexible-viewing .equation .multiplicand,
body.homepage section.flexible-viewing .equation .multiplier,
body.homepage section.flexible-viewing .equation .product {
  display: inline-block;
  position: relative;
}
body.homepage section.flexible-viewing .equation .multiplicand > span,
body.homepage section.flexible-viewing .equation .multiplier > span,
body.homepage section.flexible-viewing .equation .product > span {
  display: block;
  font-size: 106px;
  font-weight: 700;
  text-align: center;
}
body.homepage section.flexible-viewing .equation .connector {
  border-left: 1px solid #464a4e;
  display: block;
  height: 30px;
  margin-bottom: 7px;
  visibility: hidden;
}
body.homepage section.flexible-viewing .equation .info {
  color: #79808c;
  position: absolute;
  font-size: 11px;
  left: 6px;
  top: -40px;
  text-align: left;
  width: 120%;
  min-width: 200px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  letter-spacing: 0.1em;
}
body.homepage section.flexible-viewing .equation .sign {
  display: inline-block;
  font-size: 106px;
  font-weight: 700;
}
body.homepage section.video-info {
  background: rgba(153, 14, 21, 0.08);
  position: relative;
}
body.homepage section.video-info:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(153, 14, 21, 0);
  border-bottom-color: #990e15;
  border-width: 14px;
  margin-left: -14px;
}
body.homepage section.video-info .container {
  background: #990e15 url(partials/~img/components/icon/videoinfobg.svg) no-repeat center center;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 80px 0;
}
body.homepage section.video-info ul {
  list-style: none;
  margin: 0 20%;
}
body.homepage section.video-info .icon {
  height: 46px;
  width: 46px;
}
body.homepage section.video-info h2 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 14px 0;
}
body.homepage section.video-info p {
  color: #e9c7c9;
  font-size: 14px;
}
body.homepage section.video-sync {
  background: rgba(241, 241, 241, 0.28);
}
body.homepage section.video-sync .container {
  *zoom: 1;
  background: #f1f1f1 url(partials/~img/partials/homepage/video-sync.jpg) no-repeat 95% top;
  background-size: auto 100%;
  padding: 0;
}
body.homepage section.video-sync .container:before,
body.homepage section.video-sync .container:after {
  display: table;
  content: "";
}
body.homepage section.video-sync .container:after {
  clear: both;
}
body.homepage section.video-sync .container:before,
body.homepage section.video-sync .container:after {
  display: table;
  content: "";
}
body.homepage section.video-sync .container:after {
  clear: both;
}
body.homepage section.video-sync img {
  float: right;
  margin-right: 4%;
  width: 54%;
}
body.homepage section.video-sync .info {
  position: relative;
  padding: 100px 0 90px 8%;
  width: 42%;
}
body.homepage section.video-sync h2 {
  color: #37393d;
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 14px;
}
body.homepage section.video-sync p {
  color: #5e6367;
  font-size: 16px;
  padding: 0 5% 30px 0;
}
body.homepage section.video-sync a {
  border-radius: 4px;
  background-clip: padding-box;
  transition: all 0.2s ease-out;
  cursor: pointer;
  background: #37393d;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 18px 22px;
  text-decoration: none;
}
body.homepage section.video-sync a:hover {
  border-radius: 8px;
  background-clip: padding-box;
  background: #222;
}
body.homepage section.active-community {
  background: rgba(255, 255, 255, 0.38);
}
body.homepage section.active-community.portugese .container {
  padding: 80px 0 0 0;
}
body.homepage section.active-community .container {
  background-color: #fff;
  padding: 84px 0 116px;
  text-align: center;
}
body.homepage section.active-community .header-icon {
  display: inline-block;
  margin-bottom: 14px;
  width: 24px;
}
body.homepage section.active-community h2 {
  color: #37393d;
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 110px;
}
body.homepage section.active-community h2:after {
  position: relative;
  width: 134px;
  height: 2px;
  background: #cdcfd3;
  content: '';
  opacity: 1;
  display: block;
  margin: 0 auto;
  top: 30px;
}
body.homepage section.active-community .rewards,
body.homepage section.active-community .feed {
  *zoom: 1;
  margin: 0 8%;
  text-align: left;
}
body.homepage section.active-community .rewards:before,
body.homepage section.active-community .feed:before,
body.homepage section.active-community .rewards:after,
body.homepage section.active-community .feed:after {
  display: table;
  content: "";
}
body.homepage section.active-community .rewards:after,
body.homepage section.active-community .feed:after {
  clear: both;
}
body.homepage section.active-community .rewards:before,
body.homepage section.active-community .feed:before,
body.homepage section.active-community .rewards:after,
body.homepage section.active-community .feed:after {
  display: table;
  content: "";
}
body.homepage section.active-community .rewards:after,
body.homepage section.active-community .feed:after {
  clear: both;
}
body.homepage section.active-community .rewards img,
body.homepage section.active-community .feed img {
  width: 50%;
}
body.homepage section.active-community > div {
  min-height: 370px;
}
body.homepage section.active-community .rewards {
  background: transparent url(partials/~img/partials/homepage/community-rewards.jpg) no-repeat 16% top;
  background-size: auto 70%;
  padding: 50px 0 80px 52%;
}
body.homepage section.active-community .feed {
  background: transparent url(partials/~img/partials/homepage/community-feed.jpg) no-repeat 88% top;
  background-size: auto 100%;
  padding: 50px 50% 0 0;
}
body.homepage section.active-community h3 {
  color: #37393d;
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 14px;
}
body.homepage section.active-community p {
  color: #5e6367;
  font-size: 16px;
  padding: 0 5% 30px 0;
  font-family: 'Open Sans', sans-serif;
}
body.homepage #footer .container {
  margin: 0 auto;
  max-width: 1660px;
}
@media (min-width: 1440px), only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 3/2) and (min-width: 768px), only screen and (min-resolution: 192dpi) and (min-width: 768px), only screen and (min-resolution: 2dppx) and (min-width: 768px) {
  body.homepage section.intro .container .phil {
    background-image: url(partials/~img/partials/homepage/intro-phil-2x.jpg);
  }
  body.homepage section.change-your-game .sample-video .example-video {
    background-image: url(partials/~img/partials/homepage/video-fan-new.jpg);
  }
  body.homepage section.video-sync .container {
    background-image: url(partials/~img/partials/homepage/video-sync-2x.jpg);
  }
  body.homepage section.active-community .rewards {
    background-image: url(partials/~img/partials/homepage/community-rewards-2x.jpg);
    background-size: auto 70%;
  }
  body.homepage section.active-community .feed {
    background-image: url(partials/~img/partials/homepage/community-feed-2x.jpg);
    background-size: auto 100%;
  }
}
@media (min-width: 768px) and (max-width: 960px) {
  body.homepage section.intro .container .phil {
    min-height: 436px;
  }
  body.homepage section.intro .container .container {
    padding: 4%;
  }
  body.homepage section.intro .container .info {
    width: 418px;
  }
  body.homepage section.intro .container h1 {
    font-size: 46px;
  }
  body.homepage section.intro .container p {
    font-size: 16px;
  }
  body.homepage section.change-your-game .container {
    padding: 64px 0 0;
  }
  body.homepage section.change-your-game .container.portugese img {
    padding-bottom: 0px;
  }
  body.homepage section.change-your-game .info {
    float: none;
    width: 58%;
    margin: 50px auto 0;
  }
  body.homepage section.change-your-game .sample-video {
    float: none;
    right: 0%;
    width: 74%;
    margin: 0 auto;
  }
  body.homepage section.flexible-viewing .equation .multiplicand > span,
  body.homepage section.flexible-viewing .equation .multiplier > span,
  body.homepage section.flexible-viewing .equation .product > span {
    font-size: 85px;
  }
  body.homepage section.flexible-viewing .equation .sign {
    font-size: 85px;
  }
  body.homepage section.video-info ul {
    margin: 0 3%;
  }
  body.homepage section.video-info ul p {
    font-size: 13px;
  }
  body.homepage section.video-sync .container {
    background-size: 40% auto;
    background-position: center top;
    padding-top: 260px;
  }
  body.homepage section.video-sync .container .info {
    padding: 90px 0 40px 8%;
    width: 60%;
    margin: 0 auto;
  }
  body.homepage section.active-community h2 {
    margin-bottom: 50px;
  }
  body.homepage section.active-community .rewards {
    background-size: auto 40%;
    background-position: center left;
    padding: 50px 0 0px 40%;
  }
  body.homepage section.active-community .feed {
    background-position: 100% center;
    background-size: auto 58%;
    padding: 50px 40% 0 0;
  }
  body.homepage section.active-community .rewards,
  body.homepage section.active-community .feed {
    margin: 0 4%;
  }
  body.homepage section.active-community p {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  body.homepage section.video-info li {
    transform: rotateZ(8deg);
    transform-origin: 0 0;
    transition: all 0.3s ease-out;
    display: inline-block;
    opacity: 0;
    padding: 0 0 0 4%;
    position: relative;
    left: 10px;
    width: 46%;
  }
  body.homepage section.video-info li.playlist {
    transition-delay: 0.2s;
  }
  body.homepage section.video-info li.daily {
    transition-delay: 0.4s;
  }
  body.homepage section.video-info li.highlights {
    transition-delay: 0.6s;
  }
  body.homepage section.video-info.in-viewport li {
    transform: rotateZ(0deg);
    transform-origin: 0 0;
    left: 0;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  body.homepage.forum-menu-open .nav-main > .mobile-forum-hamburger {
    float: none;
    position: absolute;
    top: 26px;
    right: -140px;
  }
  body.homepage {
    padding-top: 0;
  }
  body.homepage .nav-main {
    background: none;
    height: 0px;
    position: absolute;
  }
  body.homepage .nav-main > .logo {
    background: transparent url(partials/~img/account/logo.png) no-repeat left top;
    background-size: 100% 100%;
    height: 60px;
    top: 15px;
    left: 45px;
    width: 60px;
  }
  body.homepage .nav-main > .logged_out-actions {
    display: none;
  }
  body.homepage .nav-main > .runitonce-poker.logged--out {
    font-size: 12px;
    right: 80px;
    top: 20px;
  }
  body.homepage .nav-main > .mobile-forum-hamburger {
    float: none;
    position: absolute;
    right: 20px;
    top: 26px;
  }
  body.homepage .nav-main > .mobile-forum-hamburger path {
    stroke: #fff;
  }
  body.homepage .nav-main > .mobile-forum-hamburger h2 {
    color: #fff;
  }
  body.homepage #body_container {
    width: 100%;
  }
  body.homepage p {
    font-size: 14px;
  }
  body.homepage section {
    width: 100%;
  }
  body.homepage section.intro .container {
    height: 100%;
  }
  body.homepage section.intro .container .phil {
    min-height: 360px;
    height: 100%;
    background-position: 76% center;
  }
  body.homepage section.intro .container .info {
    left: 15px;
    top: 110px;
    width: auto;
  }
  body.homepage section.intro .container .info a.russian-plans-pricing {
    position: relative;
    top: 0px;
    right: 0px;
    display: inline-block;
    margin-top: 15px;
  }
  body.homepage section.intro .container h1 {
    width: 60%;
    font-size: 32px;
    line-height: 1.2;
  }
  body.homepage section.intro .container p {
    color: #bbb;
    font-size: 16px;
    line-height: 1.6;
    width: 80%;
  }
  body.homepage section.intro .container .signature {
    display: none;
  }
  body.homepage section.intro .container .login,
  body.homepage section.intro .container .plans-pricing {
    display: inline-block !important;
    font-size: 13px;
    padding: 10px 0;
    width: 44%;
    text-align: center;
    white-space: nowrap;
  }
  body.homepage section.intro .container .login span,
  body.homepage section.intro .container .plans-pricing span {
    display: inline;
  }
  body.homepage section.intro .container .plans-pricing {
    margin-right: 20px;
  }
  body.homepage section.intro .container .plans-pricing .arrow-bg {
    display: none;
  }
  body.homepage section.intro .container .login {
    background: #656b72;
  }
  body.homepage section.intro .container .login:hover {
    background: #5b6167;
  }
  body.homepage section.change-your-game .container {
    padding: 24px 0;
  }
  body.homepage section.change-your-game .container.portugese img {
    padding-bottom: 0px;
    min-width: 280px;
  }
  body.homepage section.change-your-game .container .inner h2 {
    font-size: calc(35px + 35 * ((100vw - 400px) / 1040));
    margin-bottom: 60px;
  }
  body.homepage section.change-your-game .container .inner .sample-video {
    clear: both;
    display: block;
    width: 100%;
    padding: 0;
    right: 0;
  }
  body.homepage section.change-your-game .container .inner .sample-video .example-video {
    background-size: cover;
    min-height: 160px;
  }
  body.homepage section.change-your-game .container .inner .sample-video .toggle-intro-video {
    width: 100%;
  }
  body.homepage section.change-your-game .container .inner .sample-video .video-trigger {
    background-size: auto 100%;
    height: 110px;
  }
  body.homepage section.change-your-game .container .inner .info {
    float: none;
    display: block;
    width: 100%;
    padding: 40px 20px 40px 20px;
    text-align: center;
    margin: 0 auto;
  }
  body.homepage section.change-your-game .container.russian .inner {
    display: block;
  }
  body.homepage section.change-your-game .container.russian .inner > .info {
    width: 80%;
  }
  body.homepage section.change-your-game .container.portugese .inner {
    display: block;
  }
  body.homepage section.change-your-game .container.portugese .inner > .info {
    width: 80%;
  }
  body.homepage section.change-your-game .container .toughest-pros {
    background: none;
    padding: 0 0 40px;
    margin-top: 10px;
  }
  body.homepage section.change-your-game .container .toughest-pros.russian {
    margin-top: -250px;
  }
  body.homepage section.change-your-game .container .toughest-pros.portugese .count {
    background-size: cover;
    background-image: url(partials/~img/partials/homepage/portugese-number-bg.jpg);
    background-position: center;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 72px;
  }
  body.homepage section.change-your-game .container .toughest-pros h2 {
    font-size: 20px;
  }
  body.homepage section.change-your-game .container .toughest-pros h2 .count {
    font-size: 72px;
    font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  }
  body.homepage section.change-your-game .container .toughest-pros p {
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    width: 80%;
    margin-bottom: 20px;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow > div {
    text-align: center;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow .encasing {
    margin-bottom: 20px;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow .prev,
  body.homepage section.change-your-game .container .toughest-pros .slideshow .next {
    margin-bottom: 30px;
    position: relative;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow .prev {
    left: -10%;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow .next {
    right: -10%;
  }
  body.homepage section.change-your-game .container .toughest-pros .slideshow .pages {
    display: none;
  }
  body.homepage section.change-your-game .container .toughest-pros a.see-roster {
    display: inline-block;
  }
  body.homepage section.flexible-viewing .container {
    padding: 60px 0 100px;
    z-index: 2000;
  }
  body.homepage section.flexible-viewing h2 {
    font-size: calc(40px + 40 * ((100vw - 400px) / 1040));
    margin-bottom: 60px;
  }
  body.homepage section.flexible-viewing p {
    max-width: 460px;
    width: 80%;
    margin-bottom: 20px;
  }
  body.homepage section.flexible-viewing .equation .multiplicand > span,
  body.homepage section.flexible-viewing .equation .multiplier > span,
  body.homepage section.flexible-viewing .equation .product > span {
    font-size: calc(45px + 30 * ((100vw - 400px) / 350));
  }
  body.homepage section.flexible-viewing .equation .info {
    position: absolute;
    font-size: 10px;
    left: 6px;
    bottom: -5px;
    text-align: left;
    letter-spacing: 0;
  }
  body.homepage section.flexible-viewing .equation .info.long {
    min-width: 165px;
    top: calc(-55px + 19 * ((100vw - 400px) / 350));
    left: calc(-10px + 15 * ((100vw - 400px) / 350));
  }
  body.homepage section.flexible-viewing .equation .sign {
    font-size: calc(55px + 55 * ((100vw - 400px) / 1040));
  }
  body.homepage section.video-info:after {
    display: none;
  }
  body.homepage section.video-info .container {
    background-size: auto 100%;
    background-position: 96% center;
    padding: 40px 0 0;
  }
  body.homepage section.video-info li {
    text-align: center;
    padding-bottom: 50px;
  }
  body.homepage section.video-sync .container {
    background-size: 58% auto;
    background-position: center top;
  }
  body.homepage section.video-sync .info {
    width: auto;
    padding: 82% 8% 50px;
  }
  body.homepage section.video-sync h2 {
    font-size: 17px;
    width: 54%;
  }
  body.homepage section.video-sync p {
    font-size: 14px;
    line-height: 1.5;
  }
  body.homepage section.active-community.portugese {
    display: none;
  }
  body.homepage section.active-community .container {
    padding: 50px 0 0;
  }
  body.homepage section.active-community h2 {
    font-size: 20px;
    margin-bottom: 70px;
  }
  body.homepage section.active-community h3 {
    font-size: 18px;
  }
  body.homepage section.active-community p {
    font-size: 14px;
    line-height: 1.5;
  }
  body.homepage section.active-community .rewards {
    padding: 210px 8% 0;
    background-size: 60% auto;
    background-position: center top;
    margin: 0;
  }
  body.homepage section.active-community .feed {
    display: none;
  }
}
@media (max-width: 1024px) {
  body.homepage #torso {
    -webkit-perspective: 0;
    perspective: 0;
  }
  body.homepage #torso .phil-bg,
  body.homepage #torso .base {
    transform: none;
    transform-origin: 0 0;
  }
}
@media (min-width: 384px) and (max-width: 767px) {
  body.homepage section.intro .container h1 {
    width: auto;
  }
  body.homepage section.intro .container .info {
    top: 90px;
  }
  body.homepage section.intro .container p {
    margin-bottom: 12px;
  }
  body.homepage section.intro .take-a-tour {
    bottom: 0px;
  }
}
@media (max-width: 384px) {
  body.homepage section.video-sync .container {
    background-size: 96% auto;
  }
  body.homepage section.active-community .rewards {
    background-size: 104% auto;
    background-position: 20px top;
  }
}
@media (max-width: 767px) {
  body.homepage .rio-modal .content {
    width: 80%;
    height: auto;
  }
}
section.get-started {
  background: black;
}
section.get-started .inner-wrapper {
  height: 425px;
  display: flex;
  background-image: url('partials/~img/components/sign-up-page-footer/sign-up-footer-bg.jpg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  max-width: 1667px;
  margin: 0 auto;
}
section.get-started img {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 65%;
}
section.get-started img:hover {
  cursor: pointer;
  transform: scale(1.05);
}
section.get-started .container {
  height: 100%;
}
section.get-started .left {
  background: black;
  width: 80%;
  clip-path: polygon(0% 0%, 100% 0%, 72% 100%, 0% 100%);
  background-image: url('partials/~img/components/sign-up-page-footer/sign-up-footer-left-bg.jpg');
}
section.get-started .left h1 {
  font-size: 40px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  color: white;
  margin-left: 20%;
  margin-top: 120px;
}
section.get-started .right {
  width: 20%;
}
@media (max-width: 1340px) {
  section.get-started img {
    left: 62%;
  }
  section.get-started .left {
    clip-path: polygon(0% 0%, 100% 0%, 66% 100%, 0% 100%);
  }
}
@media (max-width: 990px) {
  section.get-started {
    height: 350px;
  }
  section.get-started img {
    left: 62%;
    width: 250px;
  }
  section.get-started .left {
    clip-path: polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%);
  }
  section.get-started .left h1 {
    margin-top: 85px;
    margin-left: 5%;
  }
}
@media (max-width: 750px) {
  section.get-started {
    height: 300px;
  }
  section.get-started img {
    left: 60%;
    width: 200px;
  }
  section.get-started .left {
    clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
    margin-top: 60px;
  }
  section.get-started .left h1 {
    font-size: 30px;
  }
}
@media (max-width: 550px) {
  section.get-started {
    height: 300px;
  }
  section.get-started img {
    left: 55%;
    width: 200px;
  }
  section.get-started .left {
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%);
  }
  section.get-started .left h1 {
    font-size: 24px;
  }
}
.btn:hover {
  transition: none 0.2s ease-out;
  text-decoration: none;
  border-color: #aaa;
}
.btn [class^="icon-"],
.btn [class*=" icon-"] {
  height: 23px;
  margin-right: 4px;
  position: relative;
  top: 6px;
  width: 23px;
}
.btn.contains_icon {
  padding: 8px 39px 14px;
}
.btn.btn-small {
  font-size: 11px;
  padding: 6px 16px;
}
.btn.btn-square {
  border-radius: 3px;
  background-clip: padding-box;
  background: #fff;
  border: 1px solid #b2babe;
  padding: 9px 30px;
  text-align: center;
}
.btn.btn-square:hover {
  border-color: #999;
}
.btn.btn-small {
  font-size: 11px;
  padding: 6px 16px;
}
.btn.btn-large {
  padding: 12px 20px 12px 20px;
}
.btn.btn-gray {
  background: #bac1c4;
  color: #fff;
}
.btn.btn-gray:hover {
  background: #B3BABE;
}
.btn.btn-dark-gray {
  background: #656b72;
  border: none;
  color: #fff;
}
.btn.btn-dark-gray:hover {
  background: #6e757c;
}
.btn.btn-black {
  background: #353535;
  border: none;
  color: #c2c2c2;
}
.btn.btn-black:hover {
  background: #3f3f3f;
}
/**
  menu-dropdowns are the notifications and messages dropdown feed things in the main nav.
 */
.menu-dropdown {
  float: left;
  position: relative;
  padding: 16px 5px;
  cursor: pointer;
}
.menu-dropdown:hover path {
  fill: #aaa !important;
}
.menu-dropdown:hover svg.icon-pro path {
  fill: #fff !important;
}
.menu-dropdown.active path {
  fill: #FFF;
}
.menu-dropdown .notification-list.menu-dropdown--dropdown {
  left: -10px;
}
.menu-dropdown .dropdown,
.menu-dropdown .menu-dropdown--dropdown {
  border-radius: 0 0 5px 5px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 3020;
  cursor: default;
  background: #fff;
  position: absolute;
  width: 410px;
  top: 45px;
  left: -5px;
  border: 1px solid #abafb1;
  opacity: 1;
}
.menu-dropdown .dropdown.ng-hide,
.menu-dropdown .menu-dropdown--dropdown.ng-hide {
  transition: all 0.1s linear;
  opacity: 0;
  top: 30px;
}
.menu-dropdown .dropdown.ng-hide-add,
.menu-dropdown .menu-dropdown--dropdown.ng-hide-add,
.menu-dropdown .dropdown.ng-hide-remove,
.menu-dropdown .menu-dropdown--dropdown.ng-hide-remove {
  display: block !important;
}
.menu-dropdown .dropdown .tab,
.menu-dropdown .menu-dropdown--dropdown .tab {
  transform: rotate(45deg);
  transform-origin: 0 0;
  height: 10px;
  width: 10px;
  background: #E5E5E5;
  position: absolute;
  top: -6px;
  left: 20px;
}
.menu-dropdown .dropdown .mast,
.menu-dropdown .menu-dropdown--dropdown .mast {
  background: rgba(230, 230, 230, 0.85);
  border-bottom: 1px solid #D4D6D5;
  padding: 10px 0 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.menu-dropdown .dropdown .mast header,
.menu-dropdown .menu-dropdown--dropdown .mast header {
  padding: 0 15px 0 15px;
  overflow: hidden;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 13px;
}
.menu-dropdown .dropdown .mast .count,
.menu-dropdown .menu-dropdown--dropdown .mast .count {
  float: left;
  font-weight: 500;
  position: relative;
  top: 5px;
  font-size: 14px;
}
.menu-dropdown .dropdown .mast .count .message-count,
.menu-dropdown .menu-dropdown--dropdown .mast .count .message-count {
  display: inline-block;
  background: #c11018;
  color: #fff;
  margin-top: -2px;
  padding: 1px 6px;
  border-radius: 2px;
  background-clip: padding-box;
  font-size: 11px;
  font-weight: normal;
  margin-right: 3px;
}
.menu-dropdown .dropdown .mast a,
.menu-dropdown .menu-dropdown--dropdown .mast a {
  float: right;
}
.menu-dropdown .list {
  height: 265px;
  overflow: auto;
  position: relative;
}
.menu-dropdown .list article a {
  color: #5d5c5c;
  display: block;
  border-bottom: 1px solid #D7D7D7;
  padding: 15px 15px;
  position: relative;
}
.menu-dropdown .list article a:hover {
  background: #fbfbfb;
  text-decoration: none;
}
.menu-dropdown .list article header {
  *zoom: 1;
}
.menu-dropdown .list article header:before,
.menu-dropdown .list article header:after {
  display: table;
  content: "";
}
.menu-dropdown .list article header:after {
  clear: both;
}
.menu-dropdown .list article header:before,
.menu-dropdown .list article header:after {
  display: table;
  content: "";
}
.menu-dropdown .list article header:after {
  clear: both;
}
.menu-dropdown .list article h3 {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 700;
  padding-bottom: 0px;
  font-size: 13px;
  letter-spacing: normal;
}
.menu-dropdown .list article .avatar {
  float: left;
  margin-right: 15px;
}
.menu-dropdown .list article .preview {
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 400;
}
.menu-dropdown .list article .unseen_indicator {
  display: block;
  top: 46%;
  left: 5px;
}
.menu-dropdown .list article.seen {
  opacity: 0.5;
}
.menu-dropdown .list article.seen .unseen_indicator {
  display: none;
}
.menu-dropdown .list article .action {
  color: #515555;
}
.menu-dropdown .list article .target {
  font-weight: 700;
}
.menu-dropdown .list article .timestamp {
  display: block;
  font-size: 11px;
  padding-top: 10px;
}
.menu-dropdown .unseen_indicator {
  position: absolute;
  background: #c11018;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-clip: padding-box;
  top: 15px;
  left: 5px;
}
.menu-dropdown .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  background-clip: padding-box;
  background: rgba(230, 230, 230, 0.85);
  border-top: 1px solid #D4D6D5;
  overflow: hidden;
  padding: 5px 0 5px 0;
}
.menu-dropdown .footer .controls {
  padding: 0 15px 0 15px;
  *zoom: 1;
}
.menu-dropdown .footer .controls:before,
.menu-dropdown .footer .controls:after {
  display: table;
  content: "";
}
.menu-dropdown .footer .controls:after {
  clear: both;
}
.menu-dropdown .footer .controls:before,
.menu-dropdown .footer .controls:after {
  display: table;
  content: "";
}
.menu-dropdown .footer .controls:after {
  clear: both;
}
.menu-dropdown .footer .btn {
  display: inline-block;
}
.menu-messages {
  margin-right: 20px;
}
.menu-messages .icon-messages {
  position: relative;
  top: -2.8px;
}
.menu-messages .icon-messages svg {
  width: 18px;
}
.menu-messages .dropdown .list,
.menu-messages .menu-dropdown--dropdown .list {
  padding-top: 50px;
}
.menu-messages .dropdown .list a:hover,
.menu-messages .menu-dropdown--dropdown .list a:hover {
  color: inherit;
}
.menu-notifications .icon-notifications {
  position: relative;
  top: 1px;
}
.menu-notifications .icon-notifications svg {
  height: 15px;
}
.menu-notifications .menu-dropdown--dropdown .tab {
  background: #fff;
}
.menu-notifications .menu-dropdown--dropdown .list {
  height: 275px;
  margin-bottom: 49px;
}
.menu-notifications .menu-dropdown--dropdown .list .notification h3 .action-text {
  font-weight: normal;
}
.menu-notifications .menu-dropdown--dropdown .list a:hover {
  color: inherit;
}
.menu-notifications .menu-dropdown--dropdown .footer a {
  float: right;
}
.menu-notifications .menu-dropdown--dropdown .footer .btn {
  float: left;
}
.menu-notifications .menu-dropdown--dropdown .footer .link-settings {
  font-size: 12px;
  position: relative;
  top: 5px;
}
.menu-notifications .menu-dropdown--dropdown .footer .link-settings:hover {
  color: #a70106;
}
.nav-drawer {
  transition: left 0.21s ease-out;
  display: flex !important;
  background-color: #fff;
  cursor: default;
  height: 100%;
  left: -311px;
  padding: 0 !important;
  position: fixed !important;
  top: 50px;
  z-index: 2990;
}
@media (max-width: 870px) {
  .nav-drawer {
    right: -331px;
    left: auto;
    top: 0;
    text-align: right;
  }
}
.nav-drawer *,
.nav-drawer *:before,
.nav-drawer *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.nav-drawer .nav-main-user {
  z-index: 200;
  width: 310px;
  padding-bottom: 2 * 50px;
  position: relative;
  background: #fff;
  border-right: 1px solid #c9c9c9;
  overflow-x: auto;
  box-shadow: 0px 0px 18px 0px rgba(5, 5, 5, 0.14);
}
.nav-drawer .nav-main-user .avatar {
  border-radius: 3px;
  background-clip: padding-box;
  border: 1px solid #d0d8d9;
  margin-right: 6px;
  background-size: 100% auto;
  height: 50px;
  position: relative;
  width: 50px;
}
.nav-drawer .nav-main-user > header,
.nav-drawer .nav-main-user > section li {
  padding: 0 12%;
}
.nav-drawer .nav-main-user > header {
  background-color: #f8f8f8;
  padding-bottom: 24px;
  padding-top: 24px;
}
.nav-drawer .nav-main-user > header > div {
  display: inline-block;
  vertical-align: top;
}
.nav-drawer .nav-main-user > header h2 {
  color: #535353;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.1;
  margin-top: 10px;
  letter-spacing: normal;
  text-transform: capitalize;
}
.nav-drawer .nav-main-user > header h2 a.points {
  font-weight: 300;
  display: inline;
}
.nav-drawer .nav-main-user > header h2 a.points:hover {
  color: #a70106;
}
.nav-drawer .nav-main-user > header h4 {
  color: #868d8d;
  font-weight: 300;
  font-size: 12px;
}
.nav-drawer .nav-main-user > .my-account,
.nav-drawer .nav-main-user > .browse,
.nav-drawer .nav-main-user > .contribute,
.nav-drawer .nav-main-user > .staff {
  margin-top: 30px;
}
.nav-drawer .nav-main-user > .my-account h2,
.nav-drawer .nav-main-user > .browse h2,
.nav-drawer .nav-main-user > .contribute h2,
.nav-drawer .nav-main-user > .staff h2 {
  color: #b3b7b7;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 14px;
  padding: 0 12%;
  letter-spacing: normal;
  text-transform: capitalize;
}
@media (max-width: 870px) {
  .nav-drawer .nav-main-user > .my-account h2,
  .nav-drawer .nav-main-user > .browse h2,
  .nav-drawer .nav-main-user > .contribute h2,
  .nav-drawer .nav-main-user > .staff h2 {
    text-transform: uppercase;
    letter-spacing: 1.1px;
  }
}
.nav-drawer .nav-main-user > .my-account ul,
.nav-drawer .nav-main-user > .browse ul,
.nav-drawer .nav-main-user > .contribute ul,
.nav-drawer .nav-main-user > .staff ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}
.nav-drawer .nav-main-user > .my-account li,
.nav-drawer .nav-main-user > .browse li,
.nav-drawer .nav-main-user > .contribute li,
.nav-drawer .nav-main-user > .staff li {
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 6px 0;
}
.nav-drawer .nav-main-user > .my-account a,
.nav-drawer .nav-main-user > .browse a,
.nav-drawer .nav-main-user > .contribute a,
.nav-drawer .nav-main-user > .staff a {
  color: #1e1f1f;
  display: block;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  width: 100%;
}
@media (max-width: 870px) {
  .nav-drawer .nav-main-user > .my-account a,
  .nav-drawer .nav-main-user > .browse a,
  .nav-drawer .nav-main-user > .contribute a,
  .nav-drawer .nav-main-user > .staff a {
    font-weight: 600;
  }
}
.nav-drawer .nav-main-user > .my-account a:hover,
.nav-drawer .nav-main-user > .browse a:hover,
.nav-drawer .nav-main-user > .contribute a:hover,
.nav-drawer .nav-main-user > .staff a:hover {
  color: #a70106;
}
.nav-drawer .nav-main-user > .my-account a:hover .icon g *,
.nav-drawer .nav-main-user > .browse a:hover .icon g *,
.nav-drawer .nav-main-user > .contribute a:hover .icon g *,
.nav-drawer .nav-main-user > .staff a:hover .icon g *,
.nav-drawer .nav-main-user > .my-account a:hover .icon .shadow,
.nav-drawer .nav-main-user > .browse a:hover .icon .shadow,
.nav-drawer .nav-main-user > .contribute a:hover .icon .shadow,
.nav-drawer .nav-main-user > .staff a:hover .icon .shadow {
  fill: #666B6B;
}
.nav-drawer .nav-main-user > .my-account a:hover .icon .add,
.nav-drawer .nav-main-user > .browse a:hover .icon .add,
.nav-drawer .nav-main-user > .contribute a:hover .icon .add,
.nav-drawer .nav-main-user > .staff a:hover .icon .add {
  fill: #fff;
}
.nav-drawer .nav-main-user > .my-account a:hover .icon.icon-settings .wheel,
.nav-drawer .nav-main-user > .browse a:hover .icon.icon-settings .wheel,
.nav-drawer .nav-main-user > .contribute a:hover .icon.icon-settings .wheel,
.nav-drawer .nav-main-user > .staff a:hover .icon.icon-settings .wheel {
  transform: rotate(360deg);
}
.nav-drawer .nav-main-user > .my-account .icon,
.nav-drawer .nav-main-user > .browse .icon,
.nav-drawer .nav-main-user > .contribute .icon,
.nav-drawer .nav-main-user > .staff .icon {
  display: inline-block;
  height: 17px;
  margin-right: 4px;
  position: relative;
  top: 2px;
  width: 17px;
}
.nav-drawer .nav-main-user > .my-account .icon g *,
.nav-drawer .nav-main-user > .browse .icon g *,
.nav-drawer .nav-main-user > .contribute .icon g *,
.nav-drawer .nav-main-user > .staff .icon g * {
  transition: all 0.2s ease-in-out;
}
.nav-drawer .nav-main-user > .my-account .icon-plans_pricing .st0,
.nav-drawer .nav-main-user > .browse .icon-plans_pricing .st0,
.nav-drawer .nav-main-user > .contribute .icon-plans_pricing .st0,
.nav-drawer .nav-main-user > .staff .icon-plans_pricing .st0 {
  fill: none !important;
}
.nav-drawer .nav-main-user > .my-account .icon .wheel,
.nav-drawer .nav-main-user > .browse .icon .wheel,
.nav-drawer .nav-main-user > .contribute .icon .wheel,
.nav-drawer .nav-main-user > .staff .icon .wheel {
  transform-origin: 50% 50%;
}
.nav-drawer .nav-main-user > .contribute {
  margin-bottom: 70px;
}
.nav-drawer .nav-notifications {
  transition: all 0.21s ease-out;
  background: #E2E3E5;
  overflow: hidden;
  position: relative;
  right: 0px;
  top: 0;
  width: 0;
  z-index: 100;
  text-align: right;
}
.nav-drawer .nav-notifications.active {
  width: 300px;
}
.nav-drawer .nav-notifications .notifications-list {
  padding: 10px;
  width: 300px;
}
.nav-drawer .nav-notifications .notifications-list .btn {
  background: #fff;
}
.nav-drawer .nav-notifications .notifications-list ul {
  margin: 0;
  padding: 0;
}
.nav-drawer .nav-notifications .notifications-list li {
  border-radius: 3px;
  background-clip: padding-box;
  border: 1px solid #D7DADE;
  text-align: left;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
}
.nav-drawer .nav-notifications .notifications-list li:hover {
  background: #F8F7F9;
}
.nav-drawer .nav-notifications .notifications-list a {
  color: #fff;
}
.nav-drawer .nav-notifications .notifications-list a:hover {
  text-decoration: none;
}
.nav-drawer .nav-notifications .notifications-list h5 {
  font-size: 12px;
  color: #535353;
  padding-bottom: 10px;
}
.nav-drawer .nav-notifications .notifications-list .action_text {
  color: #fff;
  font-weight: normal;
}
.nav-drawer .nav-notifications .notifications-list .user,
.nav-drawer .nav-notifications .notifications-list .thread_title {
  color: #535353;
  font-weight: 500;
}
.nav-drawer .nav-notifications .notifications-list p {
  font-size: 12px;
  padding-bottom: 10px;
}
.nav-drawer .nav-notifications .notifications-list .pub_date {
  color: #AAACAE;
  font-size: 11px;
}
.nav-drawer .nav-notifications .notifications-list .seen_true {
  color: #999c9c;
  background: #f0f1f2;
}
.nav-drawer .nav-notifications .notifications-list .seen_true h5,
.nav-drawer .nav-notifications .notifications-list .seen_true a,
.nav-drawer .nav-notifications .notifications-list .seen_true .action_text,
.nav-drawer .nav-notifications .notifications-list .seen_true .user,
.nav-drawer .nav-notifications .notifications-list .seen_true .thread_title {
  color: #999c9c !important;
}
.nav-drawer .close {
  position: absolute;
  padding: 20px;
}
.nav-drawer .close .icon-close {
  display: block ;
  width: 20px;
  height: 20px;
}
.nav-drawer .close .icon-close:hover {
  cursor: pointer;
}
body.has_notifications .nav-drawer .nav-main-user .icon-notifications path {
  fill: #B21219;
}
body.has_messages .nav-drawer .nav-main-user .icon-messages path {
  fill: #B21219;
}
.global-nav--mobile,
.global-nav--desktop {
  height: 50px;
  position: fixed;
  z-index: 5000;
  top: 0;
  width: 100%;
  background-color: #0e1217;
  text-rendering: optimizeLegibility;
  display: flex;
}
.global-nav--mobile {
  display: none;
}
@media (max-width: 870px) {
  .global-nav--mobile {
    display: flex !important;
  }
  .global-nav--desktop {
    display: none !important;
  }
}
.global-nav.dropdown-active .global-nav__section > span:not(.top-level):not(.more-link):not(.game_type),
.global-nav.dropdown-active .global-nav__section > ul > li > a:not(.top-level):not(.more-link):not(.game_type) {
  color: #7f858c !important;
}
.global-nav .main-content-container {
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: -1;
}
@media (max-width: 1154px) {
  .global-nav .main-content-container {
    justify-content: flex-start;
    padding-left: 40px;
  }
}
@media (max-width: 870px) {
  .global-nav .main-content-container {
    padding-left: 5vw;
  }
}
@media (max-width: 1154px) {
  .global-nav .main-content-container.logged_in {
    padding-left: 230px;
  }
}
@media (max-width: 870px) {
  .global-nav .main-content-container {
    position: absolute;
    display: block;
    left: -100vw;
    transition: left 0.2s ease-out;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    background: #0e1217;
    flex-direction: column;
    z-index: 4;
    overflow-y: scroll;
    padding-bottom: 150px;
  }
  .global-nav .main-content-container.open-section-mobile {
    overflow-y: hidden;
  }
}
.global-nav__section {
  display: flex;
  align-items: center;
}
@media (max-width: 870px) {
  .global-nav__section {
    flex-direction: column;
    align-items: flex-start;
  }
}
.global-nav__section.branding {
  margin-right: 2.5vw;
  padding-left: 2vw;
}
@media (max-width: 1154px) {
  .global-nav__section.branding {
    padding-left: 0;
  }
}
.global-nav__section.branding > a {
  padding: 0;
  height: 100%;
}
.global-nav__section.branding .logo {
  transition: opacity 0.2s ease-out;
  background: url("nav/~img/rio/menu/red_&_white.svg") no-repeat 50% center;
  background-size: 128px auto;
  min-width: 128px;
  position: relative;
}
@media (max-width: 1154px) and (min-width: 870px) {
  .global-nav__section.branding .logo {
    background: url("nav/~img/rio/menu/rio-logo.png") no-repeat 50% center;
    background-size: 16px auto;
    min-width: 50px;
  }
}
.global-nav__section.branding .logo.on-visions-page:before {
  content: '';
  background: #0e1217;
  background-image: url("nav/~img/rio/menu/vision-avatar-red.png");
  background-size: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  left: -8px;
}
.global-nav__section.branding .logo:hover {
  opacity: 0.8;
}
.global-nav__section.branding.logged_in {
  margin-right: 0;
}
.global-nav__section.branding.logged_in .logo {
  background: url("nav/~img/rio/menu/rio-logo.png") no-repeat 50% center;
  background-size: 16px auto;
  min-width: 50px;
}
.global-nav__section.branding.logged_in .logo.on-visions-page {
  background: #0e1217;
  background-image: url("nav/~img/rio/menu/vision-avatar-red.png");
  background-size: 34px 34px;
  background-repeat: no-repeat;
  background-position: center;
}
.global-nav__section.branding.logged_in .logo.on-visions-page:before {
  display: none;
}
.global-nav__section.branding.logged_in:after {
  display: inline-block;
  height: 30px;
  border-left: 1px solid #3f444d;
  position: relative;
  margin: 0 2.5vw;
  content: "";
  vertical-align: middle;
}
@media (max-width: 870px) {
  .global-nav__section.branding.logged_in:after {
    display: none;
  }
}
@media (max-width: 870px) {
  .global-nav__section.branding.logged_in {
    margin: 0 auto;
    transform: translateX(-52px);
    min-width: 100vw;
    z-index: -1;
  }
}
@media (max-width: 870px) {
  .global-nav__section.branding:not(.logged_in) {
    margin-right: auto;
    margin-left: 10px;
  }
}
@media (max-width: 870px) {
  .global-nav__section.provideos {
    padding-top: 30px;
  }
}
.global-nav__section.more {
  position: relative;
  background: url("nav/~img/rio/menu/three_dots.svg") no-repeat 50% center;
  background-size: 24px auto;
}
@media (max-width: 870px) {
  .global-nav__section.more {
    background: none;
  }
}
.global-nav__section.more .trigger-nav-dropdown {
  color: transparent !important;
}
.global-nav__section.logins {
  position: absolute;
  right: 0;
  height: 50px;
  flex-direction: row;
  align-items: center;
}
.global-nav__section.logins a.login,
.global-nav__section.logins a.signup {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
}
@media (max-width: 870px) {
  .global-nav__section.logins a.login,
  .global-nav__section.logins a.signup {
    padding: 0 7px;
  }
}
.global-nav__section.logins a.signup {
  padding: 5px 20px;
  background: #cf0f1b;
  color: white;
  border-radius: 20px;
  height: auto;
  margin-right: 35px;
  margin-left: 10px;
}
@media (max-width: 870px) {
  .global-nav__section.logins a.signup {
    padding: 5px 10px;
    margin: auto 5px;
  }
}
.global-nav__section.hamburger {
  align-items: center;
  padding: 5px 10px;
  justify-content: center;
}
.global-nav__section.hamburger .icon {
  display: block;
  height: 32px;
  width: 32px;
}
.global-nav__section.hamburger:hover {
  cursor: pointer;
}
.global-nav .section-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
@media (max-width: 1154px) {
  .global-nav .section-label {
    display: none;
  }
}
.global-nav .section-label-mobile {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 30px 7vw 7px;
}
.global-nav .dropdown-label {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #a7b0bc;
  padding: 7px 7vw;
}
.global-nav .icon-downarrow {
  width: 11px;
  height: 11px;
  margin-left: 6px;
}
@media (max-width: 870px) {
  .global-nav .icon-downarrow {
    display: none;
  }
}
.global-nav .icon-close {
  width: 20px;
  height: 20px;
}
.global-nav .icon-close:hover {
  cursor: pointer;
}
.global-nav button.search-toggle {
  position: absolute;
  right: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  background-color: #0e1217;
  border: none;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.global-nav button.search-toggle > svg {
  display: inline-block;
  position: relative;
  width: 18px;
}
.global-nav button.search-toggle > svg path {
  transition: fill 0.2s ease-out;
}
.global-nav button.search-toggle:hover path {
  fill: #fff;
}
.global-nav .more-link.search .icon.icon-search {
  height: 16px;
  width: 16px;
  top: 2px;
  fill: #fff;
}
.global-nav section.search-dropdown {
  position: fixed;
  top: 50px;
  max-height: 200px;
  width: 100%;
  background: #fff;
  left: 0;
  right: 0;
  padding: 40px 30px;
  z-index: 3010;
  transition: all 0.5s ease-out;
  box-shadow: 0 16px 35px 0 rgba(0, 0, 0, 0.3);
  display: none;
}
.global-nav section.search-dropdown input {
  background: none;
  border: none;
  box-shadow: none;
  display: block;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 45px;
  color: #abadb0;
  font-weight: 700;
  height: 90px;
  line-height: 1.2;
  margin: 0 auto;
  outline: none;
  padding: 0;
  text-align: left;
  width: 92%;
}
.global-nav section.search-dropdown input:focus {
  box-shadow: none;
}
.global-nav section.search-dropdown.active {
  display: block;
}
.global-nav .backwards-and-close {
  position: absolute;
  top: 0;
  width: 100vw;
  left: -100vw;
  transition: left 0.2s ease-out;
  z-index: 10;
  height: 50px;
  background: #0e1217;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 10vw;
}
.global-nav .backwards-and-close.show {
  left: 0;
}
.global-nav .backwards-and-close .backwards {
  background: url('nav/~img/partials/learning-paths/back-arrow.png');
  filter: brightness(0) invert(1);
  width: 40px;
  height: 15px;
  background-size: 100% 100%;
  visibility: hidden;
}
.global-nav .backwards-and-close .backwards:hover {
  cursor: pointer;
}
.global-nav .backwards-and-close.section-open .backwards {
  visibility: visible;
}
.global-nav__section:not(.user_menu) ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  height: 100%;
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) ul {
    flex-direction: column;
  }
}
.global-nav__section:not(.user_menu) .trigger-nav-dropdown,
.global-nav__section:not(.user_menu) a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 0 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
}
.global-nav__section:not(.user_menu) .trigger-nav-dropdown:hover,
.global-nav__section:not(.user_menu) a:hover {
  text-decoration: none;
  color: #ffffff !important;
}
.global-nav__section:not(.user_menu) .trigger-nav-dropdown:hover.game_type,
.global-nav__section:not(.user_menu) a:hover.game_type {
  background: #28303e;
  border-radius: 10px;
}
@media (max-width: 1154px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown,
  .global-nav__section:not(.user_menu) a {
    padding: 0 15px;
  }
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown,
  .global-nav__section:not(.user_menu) a {
    padding: 7px 7vw;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
}
.global-nav__section:not(.user_menu) .nav-dropdown {
  display: none;
  font-family: 'Open Sans', sans-serif;
}
.global-nav__section:not(.user_menu) .nav-dropdown.active,
.global-nav__section:not(.user_menu) .nav-dropdown:hover {
  display: flex;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal {
  position: absolute;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px + 0.4 * 50px;
  background-color: #191f28;
  border-radius: 10px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal > li {
  height: #191f28;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.vision {
  left: auto !important;
  transform: translateX(-48px) !important;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses {
  justify-content: flex-start;
  max-width: 1050px;
  width: 100vw;
  margin: 0 auto;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--inner {
  display: flex;
  padding: 40px min(2vw, 40px);
  flex-grow: 1;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--categories-list ul {
  display: block;
  padding: 0;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--categories-list li {
  display: block;
  font-size: 25px;
  padding: 10px 15px;
  color: #3d4552;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--categories-list .courses-nav--category-title.active {
  color: #fff;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--categories {
  border-left: 1px solid #262c35;
  padding-left: min(4vw, 60px);
  display: flex;
  flex-grow: 4;
  align-items: center;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--category {
  display: none;
  gap: 10px;
  flex-grow: 1;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--category.active {
  display: flex;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course {
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 190px;
  text-align: center;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course:hover {
  transform: scale(1.05);
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course .course-title {
  display: none;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course .course-video-count {
  position: relative;
  background: #fff;
  display: inline-block;
  margin: 0 auto;
  color: #000;
  font-size: 12px;
  border-radius: 12px;
  padding: 2px 15px;
  top: 185px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-from-the-ground-up {
  background-image: url('nav/~img/partials/nav-main/course-from-the-ground-up.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-from-the-ground-up-mtt {
  background-image: url('nav/~img/partials/nav-main/course-from-the-ground-up-mtt.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-from-the-ground-up-plo {
  background-image: url('nav/~img/partials/nav-main/course-from-the-ground-up-plo.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-from-the-ground-up-sng {
  background-image: url('nav/~img/partials/nav-main/course-from-the-ground-up-sng.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-plo-puzzle {
  background-image: url('nav/~img/partials/nav-main/course-plo-puzzle.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-a-game-poker-masterclass {
  background-image: url('nav/~img/partials/nav-main/course-a-game-poker-masterclass.jpg');
  background-position-y: -1px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-this-is-plo {
  background-image: url('nav/~img/partials/nav-main/course-this-is-plo.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-pads-on-pads {
  background-image: url('nav/~img/partials/nav-main/course-pads-on-pads.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-knockout-tournament-mastery {
  background-image: url('nav/~img/partials/nav-main/course-knockout.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-from-the-ground-up-5card-plo {
  background-image: url('nav/~img/partials/nav-main/from-the-ground-up-5card-plo.jpg');
  background-size: contain;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-dominate-with-data {
  background-image: url('nav/~img/partials/nav-main/course-dominate-with-data.jpg');
  background-size: contain;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-the-game-plan {
  position: relative;
  background-image: url('nav/~img/partials/nav-main/course-the-game-plan.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-the-game-plan:after {
  content: "Learn to Study Like the Best";
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #fff;
  border-image: url("nav/~img/courses/the-game-plan/dotted-border.png") 9/4px 4px 4px 4px/0 0 0 0 round;
  position: absolute;
  top: 50%;
  left: 5%;
  padding: 7px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.courses .courses-nav--course.course-foundations {
  background-image: url('nav/~img/partials/nav-main/course-foundations.jpg');
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal.forums {
  justify-content: center;
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal a {
  color: #a4abae;
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--horizontal a {
    color: #fff;
  }
}
.global-nav__section:not(.user_menu) .nav-dropdown--horizontal a:hover {
  color: #ffffff;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical {
  position: absolute;
  flex-direction: column;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li {
  width: 245px;
  padding: 0 15px;
  height: 50px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a {
  justify-content: space-between;
  background: rgba(44, 50, 58, 0.95);
  padding: 8px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a .stakes-name {
  white-space: nowrap;
  padding-left: 15px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical {
  position: absolute;
  flex-direction: column;
  top: 50px;
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical {
    padding-top: 0;
    margin-bottom: 100px;
  }
}
@media (pointer: coarse) and (hover: none) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical:not(.more) > li:first-child {
    padding-top: 10px;
  }
}
@media not (pointer: coarse) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical:not(.more) > li:nth-child(2) {
    padding-top: 10px;
  }
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical:not(.more) > li:last-child {
  padding-bottom: 10px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical.more > li:first-child {
  padding-top: 10px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical.more > li:last-child {
  padding-bottom: 10px;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li {
  width: auto;
  min-width: 165px;
  background-color: #0e1217;
  opacity: 0.95;
  white-space: nowrap;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a {
  padding: 12px 25px;
  background: transparent;
}
.global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a:hover {
  background: #28303e;
  border-radius: 10px;
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a:hover {
    background: transparent;
  }
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li a {
    padding: 7px 7vw;
  }
}
@media (min-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li:first-child {
    padding-top: 10px;
    border-radius: 7px 7px 0 0;
  }
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li:first-child:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #0e1217;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: 2px;
    left: 25px;
  }
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li:last-child {
    padding-bottom: 10px;
    border-radius: 0 0 7px 7px;
  }
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical,
  .global-nav__section:not(.user_menu) .nav-dropdown--horizontal,
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical {
    flex-direction: column;
    position: static;
    display: block;
    background: transparent;
    height: 100%;
    transform: none;
  }
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical {
    margin-bottom: 50px;
  }
  .global-nav__section:not(.user_menu) .nav-dropdown--vertical > li {
    padding: 0;
  }
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown.center-inner-dropdown-at-850 ~ .nav-dropdown {
    right: 50%;
    transform: translateX(50%);
  }
}
@media (max-width: 1154px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown.align-inner-dropdown-right-at-960px ~ .nav-dropdown {
    right: 0;
  }
}
@media (max-width: 1154px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown.align-inner-dropdown-right-at-1154px ~ .nav-dropdown {
    right: 0;
  }
}
.global-nav__section:not(.user_menu) .trigger-nav-dropdown.active ~ .nav-dropdown,
.global-nav__section:not(.user_menu) .trigger-nav-dropdown:hover ~ .nav-dropdown {
  display: flex;
}
@media (pointer: coarse) and (hover: none) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.active) ~ .nav-dropdown {
    display: none;
  }
}
@media (max-width: 870px) {
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile):after {
    display: inline-block;
    content: "+";
    position: relative;
    margin: 0 1vw;
    color: #fff;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical {
    left: -100vw;
    position: absolute;
    overflow-y: scroll;
    z-index: 5;
    top: 0;
    right: auto;
    height: 100%;
    min-height: 100vh;
    transition: left 0.2s ease-out;
    width: 100vw;
    background: #0e1217;
    display: block;
    padding-top: calc(50px + 0.4 * 50px);
    padding-bottom: 150px;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li {
    width: auto;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li a,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li a {
    height: 100%;
    background: transparent;
    display: block;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-weight: 600;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li a .stakes-name,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li a .stakes-name {
    padding: 7px 0 7px 7vw;
    display: inline-block;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li a .stakes-count,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li a .stakes-count {
    color: transparent;
    background: transparent;
    display: inline-block;
    width: auto;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li a .stakes-count > span,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li a .stakes-count > span {
    color: #b5b9be;
    font-size: 1.1em;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li.add-thread,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li.add-thread {
    max-width: 80vw;
    height: 60px;
    margin: 25px 10vw;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li.add-thread a,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li.add-thread a {
    display: flex;
    border-radius: 4px;
    background-clip: padding-box;
    padding-left: 0;
    overflow: hidden;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li.add-thread a .stakes-name,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li.add-thread a .stakes-name {
    background: #626c78;
    white-space: normal;
    padding: 5px 15px;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--horizontal > li.add-thread a .stakes-count,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile) ~ .nav-dropdown--vertical > li.add-thread a .stakes-count {
    background: #727b86;
    align-self: center;
    justify-content: center;
    display: flex;
    color: #fff;
    flex-grow: 1;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile).active ~ .nav-dropdown--horizontal,
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown:not(.unfold-mobile).active ~ .nav-dropdown--vertical {
    left: 0;
  }
  .global-nav__section:not(.user_menu) .trigger-nav-dropdown.unfold-mobile ~ .nav-dropdown {
    display: flex;
  }
}
.global-nav .user_menu-logo_name {
  transition: left 0.4s linear;
  height: 45px;
  display: table;
  position: relative;
  list-style: none;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .fullscreen-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background: rgba(14, 18, 23, 0.9);
  cursor: pointer;
  display: none;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav {
  height: 50px;
  padding-right: 20px;
  position: relative;
  min-width: 65px;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav > svg {
  transition: fill 0.2s ease-out;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .avatar img {
  border-radius: 3px;
  background-clip: padding-box;
  border: none;
  overflow: hidden;
  width: 27px;
  height: 27px;
  display: inline-block;
  position: relative;
  top: 12px;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .icon-user_rightarrow {
  height: 10px;
  width: 10px;
  top: 3px;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  transform: translateX(0);
  transform-origin: 0 0;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .icon-user_rightarrow line {
  transition: all 0.2s ease-in-out;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .icon-user_rightarrow .top-right {
  opacity: 0;
  transform: translateX(-12px) translateY(13px);
  transform-origin: 0 0;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .icon-user_rightarrow .bottom-right {
  opacity: 0;
  transform: translateX(-12px) translateY(-13px);
  transform-origin: 0 0;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav:hover {
  cursor: pointer;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav:hover line {
  fill: #aaa;
  stroke: #aaa;
}
.global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav:hover .icon-user_rightarrow {
  left: 2px;
}
@media (max-width: 870px) {
  .global-nav .user_menu-logo_name {
    position: absolute;
    right: 0;
  }
  .global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav {
    padding-left: 20px;
    padding-right: 3vw;
  }
  .global-nav .user_menu-logo_name > .menu-user-toggle .btn-user-nav .icon-user_rightarrow {
    transform: rotate(180deg) translateX(45px);
    transform-origin: 0 0;
    top: 12px;
    left: 6px;
  }
}
@media (max-width: 870px) {
  .global-nav.nav-open .main-content-container {
    left: 0;
  }
}
@media (max-width: 870px) {
  .global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .fullscreen-mask {
    display: block;
  }
}
.global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .icon-user_rightarrow {
  left: -2px;
  transform: translateX(6px);
  transform-origin: 0 0;
}
.global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .icon-user_rightarrow .top-right {
  opacity: 1;
  transform: translateX(0) translateY(0);
  transform-origin: 0 0;
}
.global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .icon-user_rightarrow .bottom-right {
  opacity: 1;
  transform: translateX(0) translateY(0);
  transform-origin: 0 0;
}
.global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .icon-user_rightarrow line {
  fill: #aaa;
  stroke: #aaa;
}
.global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .nav-drawer {
  left: 0;
  opacity: 1;
}
@media (max-width: 870px) {
  .global-nav.user-menu-open .user_menu-logo_name > .menu-user-toggle .nav-drawer {
    left: auto;
    right: 0;
    opacity: 1;
  }
}
.global-nav .mobile--only {
  display: none;
}
@media (max-width: 870px) {
  .global-nav .mobile--only {
    display: flex;
  }
  .global-nav .mobile--hide {
    display: none !important;
  }
}
.global-nav .touchscreen--only {
  display: none;
}
@media (pointer: coarse) and (hover: none) {
  .global-nav .touchscreen--only {
    display: inline-block !important;
  }
}
@media (max-width: 375px) {
  .global-nav .global-nav__section.logins a.signup {
    margin: auto 5px;
    padding: 5px 10px;
    font-size: 11px;
  }
}
#site_message .countdown {
  font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 auto;
  color: #fff;
  line-height: 1.2;
  visibility: hidden;
  display: inline-block;
  position: relative;
  top: -1px;
}
#site_message .countdown span {
  position: relative;
  display: inline-block;
  top: 1px;
}
body.pro-vs-pro #torso > section .container {
  margin: 0 auto;
  max-width: 1660px;
}
body.pro-vs-pro #torso > section.intro {
  text-align: center;
}
body.pro-vs-pro #torso > section.intro .container {
  background: #fff url(partials/~img/video/pro-vs-pro/intro-texture.jpg) no-repeat center 430px;
  background-size: 100% auto;
}
body.pro-vs-pro #torso > section.intro .container > h4 {
  border-bottom: 1px solid #d6dde0;
  color: #a5a9ab;
  display: inline-block;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 48px 16px 18px;
}
body.pro-vs-pro #torso > section.intro .container > h1 {
  color: #1f272a;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 75px;
  font-weight: 700;
  text-transform: uppercase;
}
body.pro-vs-pro #torso > section.intro .container > h1 span {
  color: #7a8183;
  font-size: 20px;
  position: relative;
  top: -18px;
}
body.pro-vs-pro #torso > section.intro .container > h5 {
  color: #383f41;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 60px;
  letter-spacing: 6px;
  text-transform: uppercase;
}
body.pro-vs-pro #torso > section.intro .video-controller {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
body.pro-vs-pro #torso > section.intro .video-container {
  background: transparent url(partials/~img/video/pro-vs-pro/video-container-overlay.jpg) no-repeat left top;
  margin: 0 auto;
  position: relative;
  max-width: 992px;
  width: 80%;
}
body.pro-vs-pro #torso > section.intro .video-container section.video_player {
  height: 100%;
}
body.pro-vs-pro #torso > section.intro .video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
body.pro-vs-pro #torso > section.intro .video-container img {
  display: block;
  width: 100%;
}
body.pro-vs-pro #torso > section.intro .video-container .timer {
  position: absolute;
  top: 38%;
  left: 0;
  width: 100%;
  z-index: 1000;
}
body.pro-vs-pro #torso > section.intro .video-container .timer .live_in {
  color: #fff;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 11px;
  font-weight: 500;
  margin: 0 auto;
  text-transform: uppercase;
}
body.pro-vs-pro #torso > section.intro .video-container .timer .countdown {
  font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
  font-size: 28px;
  font-weight: 400;
  margin: 0 auto;
  color: #fff;
  line-height: 1.2;
}
body.pro-vs-pro #torso > section.intro .video-container .timer .countdown span {
  position: relative;
  display: inline-block;
  top: 2px;
}
body.pro-vs-pro #torso > section.intro .video-container .timer .countdown .seconds {
  color: #a81313;
  font-size: 31px;
  top: 3px;
}
body.pro-vs-pro #torso > section.intro .video-container .video-trigger-area {
  background: transparent url(partials/~img/video/pro-vs-pro/video-trigger.png) no-repeat left top;
  background-size: 100% 100%;
  width: 220px;
  height: 220px;
  position: absolute;
  top: 28%;
  margin: 0 auto;
  left: 39%;
}
body.pro-vs-pro #torso > section.intro .video-container .play {
  position: absolute;
  left: 46.25%;
  top: 44%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent #ffffff;
}
body.pro-vs-pro #torso > section.intro .video-container .live {
  color: #bfc4c5;
  font-family: Garamond, Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  left: -62px;
  top: -16px;
  text-transform: uppercase;
  position: absolute;
}
body.pro-vs-pro #torso > section.intro .video-container .live:after {
  content: ' ';
  position: absolute;
  width: 1px;
  height: 38px;
  background: #bfc4c5;
  right: -21px;
  transform: rotate(38deg);
  transform-origin: 0 0;
  top: 1px;
}
body.pro-vs-pro #torso > section.intro .about {
  background: #101111;
  margin-top: 180px;
  padding-top: 30px;
}
body.pro-vs-pro #torso > section.intro .about h2 {
  color: #ffffff;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 22px;
  font-weight: 400;
  max-width: 230px;
  margin: 0 auto;
}
body.pro-vs-pro #torso > section.intro .about p {
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 500px;
  padding: 40px 0 10px;
}
body.pro-vs-pro #torso > section.intro .about .transition {
  background: #101111;
  height: 200px;
  display: inline-block;
  width: 100%;
}
body.pro-vs-pro #torso > section.intro .about .transition div {
  background: #282a2a;
  width: 2px;
  margin: 40px auto 0;
  height: 160px;
  top: 0;
}
body.pro-vs-pro #torso > section.pros {
  background: #fff;
  /* simple */
  /* START: Accommodating for IE */
  /* END: Accommodating for IE */
}
body.pro-vs-pro #torso > section.pros .container {
  background: transparent url(partials/~img/video/pro-vs-pro/pros-bg.jpg) no-repeat center top;
  background-size: auto 100%;
}
body.pro-vs-pro #torso > section.pros h2 {
  color: #1f272a;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0 auto;
  padding: 94px 0;
  text-align: center;
}
body.pro-vs-pro #torso > section.pros ul {
  margin: 0 auto;
  position: relative;
  width: 1240px;
}
body.pro-vs-pro #torso > section.pros ul.top {
  left: 70px;
}
body.pro-vs-pro #torso > section.pros ul.bottom {
  position: relative;
  top: -70px;
  left: -50px;
  padding-bottom: 68px;
}
body.pro-vs-pro #torso > section.pros li {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  display: inline-block;
  margin: 0 35px;
  position: relative;
}
body.pro-vs-pro #torso > section.pros li:hover .back,
body.pro-vs-pro #torso > section.pros li.hover .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
body.pro-vs-pro #torso > section.pros li:hover .front,
body.pro-vs-pro #torso > section.pros li.hover .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
body.pro-vs-pro #torso > section.pros li,
body.pro-vs-pro #torso > section.pros .front,
body.pro-vs-pro #torso > section.pros .back {
  width: 170px;
  height: 170px;
}
body.pro-vs-pro #torso > section.pros .flipper {
  -webkit-transition: 0.4s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.4s;
  -moz-transition: 0.5s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 0.4s;
  transform-style: preserve-3d;
  position: relative;
}
body.pro-vs-pro #torso > section.pros .flipper.jens_kyllnen .front img {
  left: -75%;
  top: -45%;
}
body.pro-vs-pro #torso > section.pros .rotation {
  transform: rotate(-45deg);
  transform-origin: 0 0;
  backface-visibility: hidden;
  border: 1px solid #fff;
  width: 120px;
  height: 120px;
  overflow: hidden;
  top: 50%;
  left: 0;
  position: relative;
}
body.pro-vs-pro #torso > section.pros .rotate-back {
  transform: rotate(45deg);
  transform-origin: 0 0;
}
body.pro-vs-pro #torso > section.pros .front,
body.pro-vs-pro #torso > section.pros .back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: absolute;
  top: 0;
  left: 0;
}
body.pro-vs-pro #torso > section.pros .front {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
}
body.pro-vs-pro #torso > section.pros .front img {
  height: 248px;
  position: relative;
  left: -60%;
  top: -32%;
  display: block;
}
body.pro-vs-pro #torso > section.pros .back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
body.pro-vs-pro #torso > section.pros .back .backside-image {
  transform: rotate(45deg) scaleX(-1);
  transform-origin: 0 0;
  height: 248px;
  position: relative;
  left: 175%;
  top: 75%;
  display: block;
  z-index: 2;
}
body.pro-vs-pro #torso > section.pros .back .rotate-back {
  background: rgba(31, 39, 42, 0.8);
  width: 170px;
  height: 170px;
  position: absolute;
  top: -20%;
  left: -20%;
  z-index: 3;
}
body.pro-vs-pro #torso > section.pros .back h3 {
  color: #ffffff;
  padding-top: 40%;
  font-weight: 500;
  font-size: 13px;
}
body.pro-vs-pro #torso > section.pros .back h4 {
  color: #747f83;
  font-weight: 400;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
body.pro-vs-pro #torso > section.pros .back h3,
body.pro-vs-pro #torso > section.pros .back h4 {
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  display: block;
  text-align: center;
}
body.pro-vs-pro #torso > section.past_videos {
  background: #ebebeb;
}
body.pro-vs-pro #torso > section.past_videos .container {
  background: #fff url(partials/~img/video/pro-vs-pro/past-videos-bg.jpg) no-repeat center top;
  padding: 180px 0 130px;
}
body.pro-vs-pro #torso > section.past_videos .video-player-image {
  background: transparent url(partials/~img/video/pro-vs-pro/past-video-container.png) no-repeat center top;
  background-size: 100% auto;
  display: block;
  height: 316px;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  width: 619px;
}
body.pro-vs-pro #torso > section.past_videos .video-player-image h2 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  position: relative;
  top: 118px;
  text-align: center;
  text-decoration: none;
}
body.pro-vs-pro #torso > section.past_videos .video-player-image .number {
  color: #bfc4c5;
  font-family: Garamond, Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
  font-style: italic;
  font-size: 18px;
  opacity: 0.8;
  left: 223px;
  top: 101px;
  text-transform: uppercase;
  position: absolute;
}
body.pro-vs-pro #torso > section.past_videos p {
  color: #7f858a;
  font-size: 14px;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 460px;
  padding: 10px 0 34px;
  text-align: center;
}
body.pro-vs-pro #torso > section.past_videos .divider {
  height: 90px;
  display: inline-block;
  width: 100%;
}
body.pro-vs-pro #torso > section.past_videos .divider div {
  background: #e0e3e6;
  width: 2px;
  margin: 0 auto;
  height: 90px;
  top: 0;
}
body.pro-vs-pro #torso > section.cta {
  background: #fff url(partials/~img/video/pro-vs-pro/cta-bg.jpg) no-repeat center top;
  background-size: auto 100%;
  min-height: 360px;
  position: relative;
}
body.pro-vs-pro #torso > section.cta h2 {
  color: #fff;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 38px;
  max-width: 650px;
  padding: 110px 0 0 180px;
  text-transform: uppercase;
  line-height: 1.3;
}
body.pro-vs-pro #torso > section.cta a {
  background: transparent url(partials/~img/video/pro-vs-pro/cta-link.png);
  background-size: 100% auto;
  position: absolute;
  width: 292px;
  height: 65px;
  left: 955px;
  top: 155px;
  line-height: 65px;
  font-size: 10px;
  color: #4f575a;
  text-align: center;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  text-transform: uppercase;
}
@media (min-width: 1520px) {
  body.pro-vs-pro #torso > section.intro .container {
    background-position: center 380px;
  }
}
@media (min-width: 1438px) {
  body.pro-vs-pro #torso > section.cta {
    background-size: 100% auto;
  }
  body.pro-vs-pro #torso > section.cta a {
    left: 68%;
  }
}
@media (max-width: 1420px) {
  body.pro-vs-pro #torso > section.intro .information {
    background: #101111;
  }
}
@media (min-width: 767px) and (max-width: 1380px) {
  body.pro-vs-pro #torso > section.pros {
    margin-bottom: -120px;
  }
  body.pro-vs-pro #torso > section.pros ul {
    width: 768px;
    padding: 0;
  }
  body.pro-vs-pro #torso > section.pros ul.top,
  body.pro-vs-pro #torso > section.pros ul.bottom {
    left: 0;
  }
  body.pro-vs-pro #torso > section.pros ul li:nth-child(5n + 4),
  body.pro-vs-pro #torso > section.pros ul li:nth-child(5n) {
    left: 125px;
    top: -58px;
  }
  body.pro-vs-pro #torso > section.pros ul.bottom {
    margin: 0 auto;
    position: relative;
    top: -110px;
  }
  body.pro-vs-pro #torso > section.cta h2 {
    font-size: 28px;
    max-width: 420px;
    padding: 60px 0 0 50px;
  }
  body.pro-vs-pro #torso > section.cta a {
    left: 65%;
  }
}
@media (max-width: 767px) {
  body.pro-vs-pro #torso > section.intro .container {
    background-position: center 340px;
  }
  body.pro-vs-pro #torso > section.intro .container .video-container .live {
    display: none;
  }
  body.pro-vs-pro #torso > section.intro .container .information {
    margin-top: 80px;
  }
  body.pro-vs-pro #torso > section.intro .container .information p {
    font-size: 12px;
    width: 80%;
  }
  body.pro-vs-pro #torso > section.pros {
    margin-bottom: -200px;
  }
  body.pro-vs-pro #torso > section.pros ul {
    padding: 0;
    width: 400px;
  }
  body.pro-vs-pro #torso > section.pros ul.top {
    left: auto;
  }
  body.pro-vs-pro #torso > section.pros ul li {
    margin: 0 10px;
  }
  body.pro-vs-pro #torso > section.pros ul li:nth-child(3n) {
    margin: 0 27%;
    top: -78px;
  }
  body.pro-vs-pro #torso > section.pros ul li:nth-child(5n + 4),
  body.pro-vs-pro #torso > section.pros ul li:nth-child(5n) {
    top: -157px;
  }
  body.pro-vs-pro #torso > section.pros ul.bottom {
    padding-bottom: 0;
    margin: 0 auto;
    position: relative;
    top: -110px;
    left: 0px;
  }
  body.pro-vs-pro #torso > section.past_videos .container {
    padding-bottom: 80px;
  }
  body.pro-vs-pro #torso > section.past_videos .video-player-image {
    background-size: 100% auto;
    height: 223.65px;
    width: 389.55px;
  }
  body.pro-vs-pro #torso > section.past_videos .video-player-image h2 {
    font-size: 16px;
    top: 82px;
  }
  body.pro-vs-pro #torso > section.past_videos .video-player-image .number {
    font-size: 14px;
    left: 121px;
    top: 70px;
  }
  body.pro-vs-pro #torso > section.past_videos p {
    font-size: 12px;
    width: 80%;
  }
  body.pro-vs-pro #torso > section.cta {
    background-position: left top;
    background-size: auto auto;
  }
  body.pro-vs-pro #torso > section.cta h2 {
    font-size: 28px;
    max-width: 340px;
    padding: 60px 0 0;
    margin: 0 auto;
  }
  body.pro-vs-pro #torso > section.cta a {
    left: auto;
    right: 0;
    top: 245px;
  }
}
.cookies-bar {
  position: fixed;
  bottom: 15px;
  right: 3vw;
  z-index: 15000000;
  display: none;
}
.cookies-bar--content {
  background-color: black;
  color: #fff;
  width: 94vw;
  font-size: 13px;
  font-family: 'nowaymedium', Verdana, Tahoma, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}
.cookies-bar--content .info {
  font-weight: 100;
  text-align: left;
}
.cookies-bar--content .info a {
  color: red;
  text-decoration: underline;
}
.cookies-bar--content .info a:hover {
  cursor: pointer;
}
.cookies-bar--content .action form {
  display: inline-block;
  float: right;
}
.cookies-bar--content .action input[type="submit"] {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  padding: 10px;
}
.cookies-bar--content .action input[type="submit"]:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .cookies-bar--content {
    font-size: 11px;
  }
}
